/* comman-blue-header */

.doctor-container {
  background-color: #f8f9fb;
  height: 100%;
  padding-bottom: 100px;
}

.blue-block {
  width: 100%;
  height: 234px;
  background: #2e5cf2;
}

.star-div .img {
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.star-div .img img {
  margin-right: 4px;
}
.star-div span {
  font-family: Montserrat;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.doctor-detailes {
  margin: 0 auto;
  max-width: 1110px;
  width: 100%;
  font-family: Montserrat;
  margin-top: -200px;
}

.detailes-top {
  display: flex;
}
.detailes-top .profile-image {
  width: 350px;
  height: 400px;
}
.right-detailes {
  width: 100%;
}

.basic-info .name {
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
}

.top-data {
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
}

.loc-rating .location {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.loc-rating .location .loc {
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: flex-end;
}
.location span {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding-left: 5px;
}
.specialties {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.specialties span {
  width: fit-content;
  background: white;
  border-radius: 7px;
  padding: 2px 10px;
  margin-top: 5px;
  margin-right: 8px;
  color: #2e5cf2;
  font-size: 13px;
  font-weight: 400;
}

.contact-info {
  margin-top: 98px;
  margin-left: 30px;
  display: flex;
  justify-content: space-between;
}
h3.contact-title {
  font-size: 18px;
  font-weight: 500;
}

.teleHealth {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
  font-size: 10px;
  color: #2e5cf2;
}

.info-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: start;
  font-size: 14px;
  font-weight: 400;
}
.info-field div {
  margin-right: 10px;
}

.back-shade {
    background: #e3e9fa;
    padding: 7px;
    border-radius: 5px;
}
.contact-data .group-data {
  margin-top: 12px;
}

.profile-image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 5px;
}

/* schedule data */

.top-arrow {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 20px;
  cursor: pointer;
}
.top-arrow span {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.schedule {
  display: flex;
  align-items: end;
  width: fit-content;
  padding-right: 20px;
}
.timings-top {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  justify-content: space-around;
  margin-top: 5px;
}
.timings-top h3 {
  padding: 0px 10px;
}
.open-text {
  color: #b37803;
}
.days {
  width: 100%;
}
.day {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: calc(50% - 16px / 2 - 39.5px);
  font-size: 13px;
  font-weight: 400;
}

.days .day span.time {
  padding-left: 22px;
  width: 135px;
}

.days .active {
  font-weight: 500;
}

/* detailes bottom */

.detailes-bottom {
  margin-top: 35px;
}
.detailes-bottom .tab-view {
  background: #ffffff;
  padding: 20px 32px;
  border-radius: 0px 15px 15px 15px;
  padding-bottom: 100px;
}
.tabs-container {
  width: 315px;
  height: 40px;
  background: #eff3fe;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs-container span {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 5px 0px 0px 0px;
}

span.graduation,
.edu-desc {
  font-size: 15px;
  font-weight: 400;
}
h3.edu-title {
  font-size: 18px;
  font-weight: 500;
}
.experience ul li {
  font-size: 15px;
  font-weight: 400;
  list-style: outside;
}
.experience ul {
  padding-left: 18px;
}
.education,
.courses,
.experience {
  margin-top: 26px;
}
.courses {
  display: flex;
  flex-direction: column;
}
.tabs-container span.active {
  background: #ffffff !important;
  color: #2e5cf2;
  font-weight: 500;
}
.tabs-container span:last-child.active {
  border-top-right-radius: 5px;
  border-top-left-radius: 0px;
}
.reviews ul.rating {
  display: flex;
  align-items: center;
}
.reviews ul.rating li {
  margin: 0 60px 0 0;
}
.reviews .reviews-inner {
  display: flex;
  justify-content: space-between;
}
.reviews .reviews-inner .left {
  width: 60%;
  padding-top: 25px;
}
.article-container .reviews .reviews-inner .left.full{
  width: 100%;
  padding-top: 25px;
}

.reviews .reviews-inner .left .star img {
  margin: 0;
  width: 11.5px !important;
  height: 11px !important;
}
.reviews .reviews-inner .right {
  width: 30%;
  padding: 20px;
}
.reviews .reviews-inner p {
  margin-top: 5px;
}
.reviews .reviews-inner ul.rating {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
}

.reviews ul.reviews-content {
  padding: 20px 0;
}
.reviews ul.reviews-content li {
  padding: 15px 0;
}
.reviews ul.reviews-content li .content-inner {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}

.reviews ul.reviews-content li .img img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 10px;
}
.reviews ul.reviews-content li .text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.reviews ul.reviews-content li .text .star {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.reviews ul.reviews-content li .right-date {
  margin-left: auto;
}
.reviews ul.reviews-content li .right-date span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}
.reviews ul.reviews-content li .bottom .like {
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.reviews ul.reviews-content li .bottom .like img {
  margin: 0 5px 0 0;
}
.reviews ul.reviews-content li .bottom .like.active {
  color: #b37803;
}
.reviews ul.reviews-content li .bottom .content-img img {
  max-width: 200px;
  max-height: 150px;
  border-radius: 20px;
  object-fit: cover;
}
.reviews ul.reviews-content li .bottom p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #4f4f4f;
  margin-bottom: 15px;
}
.reviews img {
  margin: 0;
}

.reviews .reviews-inner .right h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.reviews .reviews-inner .right .star-inner {
  display: flex;
  align-items: center;
  padding: 10px 0 20px;
  border-bottom: 1px solid #dadada;
}
.reviews .reviews-inner .right .star-inner img {
  margin-right: 28px;
  width: 26.14px;
  height: 25px;
}

.reviews .reviews-inner .right form {
  padding: 15px 0;
}
.reviews .reviews-inner .right form .form-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 10px 0 15px;
}
.reviews .reviews-inner .right form textarea,
.reviews .reviews-inner .right form input {
  background: #f6f6f6;
  border-radius: 10px;
  border: 0;
  padding: 10px;
  font-size: 15px;
  color: #4f4f4f;
  resize: none;
}
.reviews .reviews-inner .right form textarea {
  padding: 15px;
}
.reviews .reviews-inner .right form .no-comment {
  border: 2px solid rgb(247, 145, 145);
}
.reviews .reviews-inner .right .file {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-top: 15px;
  width: 100%;
}

.reviews .reviews-inner .right .btn {
  color: gray;
  background: #f6f6f6 !important;
  width: 100%;
  font-weight: normal;
  font-size: 15px !important;
  line-height: 18px !important;
  padding-left: 40px;
  position: relative;
}
.reviews .reviews-inner .right .btn:before {
  content: "";
  position: absolute;
  top: 50%;
  background-image: url("../../../assets/images/cemero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  width: 20px;
  height: 20px;
  transform: translate(10px, -50%);
}
.reviews .reviews-inner .right input[type="file"] {
  width: 100%;
  max-width: 100%;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.reviews .reviews-inner .right input[type="submit"]:hover {
  background-color: #fff;
  color: #2e5cf2;
}
.reviews .reviews-inner .right input[type="submit"] {
  display: block;
  transition: all 0.3s;
  margin: 15px 0;
  width: 100%;
  background: #2e5cf2;
  border: 1px solid #2e5cf2;
  border-radius: 10px;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

.reviews .reviews-inner .right textarea::-webkit-input-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.reviews .reviews-inner .right textarea:-ms-input-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.reviews .reviews-inner .right textarea::placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}
.reviews-inner .rating span {
  font-size: 18px;
  font-weight: 500;
}
.reviews-inner .rating p {
  font-size: 13px;
  font-weight: 400;
}

.loc-rating .star-div .img span {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 0px !important;
}


.loc-rating .star-div .img img {
  width: 14px !important;
  height: 14px !important;
}
.loc-rating .star-div .img .rev-count {
  padding-left: 10px;
}
ul.loader.doctor-loader {
  display: flex;
  margin-top: 30px;
}
li.review-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.doctor-container .image-preview img {
  border-radius: 5px !important;
}
.doctor-container .image-preview {
  margin-bottom: 20px;
  height: 80px;
}
.doctor-container .image-preview span {
  font-size: 11px;
  background: #2e5cf2;
  width: 20px !important;
  color: white;
  border-radius: 20px;
  height: 20px !important;
  cursor: pointer;
}

.comment-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.contact-data.website a{
  text-decoration: none;
}



@media(max-width: 1024px)
{
  .doctor-detailes {
    padding: 15px;
}
.star-div {
  width: 100%;
}
.back-shade {
   width: 30px;
}
.timings-top h3 {
  padding: 0px 10px;
  font-size: 14px;
}
.reviews .reviews-inner .right .star-inner img {
  margin-right: 6px;
  
}
.reviews ul.rating li {
  margin: 0 20px 0 0;
}


}

@media(max-width: 991px)
{
  .reviews .reviews-inner .right {
    width: 30%;
    padding: 20px 0;
}
.reviews .reviews-inner .right form textarea {
 width: 100%;
}
}
@media(max-width: 767px)
{
  .reviews .reviews-inner {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.reviews .reviews-inner .left {
  width: 100%;
  padding-top: 25px;
}
.reviews .reviews-inner .right {
  width: 100%;
  padding: 20px 0;
  order: -1;
}
.reviews .reviews-inner .right form textarea {
  padding: 15px;
  width: 100%;
}
.detailes-top {
  display: flex;
  flex-direction: column;
  position: relative;
}
.detailes-top .profile-image {
  width: 180px;
  height: 200px;
}
.contact-info {
  margin-top: 20px;
  margin-left: 0;
  flex-direction: column;
}
.doctor-detailes {
  
  margin-top: -240px;
}
.top-data {
  margin-left: 0;
  position: absolute;
  top: 0;
  flex-direction: column;
  left: 200px;
}
.loc-rating .location {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
}
.specialties {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
}

@media(max-width: 480px)
{
  .reviews ul.rating li {
    margin: 0 6px 0;
    width: calc(100% / 3 - 10px);
}
.reviews ul.rating li p {
  word-break: break-all;
}
.reviews .reviews-inner ul.rating {
    align-items: flex-start;
}
.detailes-bottom .tab-view {
  padding: 20px 10px;
  
}
.reviews-inner .rating span {
  font-size: 24px;
  font-weight: 500;
}
.reviews-inner .rating p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}
}