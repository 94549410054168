
.switch-container {
    display: flex;
    align-items: center;
    margin-right: 14px;
}
input[type="checkbox"].switch  {
	position: absolute;
	opacity: 0;
}

input[type="checkbox"].switch + div {
	vertical-align: middle;
	width: 44px;
	height: 23px;
	border-radius: 999px;
	background-color: #EFF3FE;
	-webkit-transition-duration: .4s;
	transition-duration: .4s;
	-webkit-transition-property: background-color, box-shadow;
	transition-property: background-color, box-shadow;
	cursor: pointer;
}

input[type="checkbox"].switch + div span {
	position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child( 1 ) {
    margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child( 2 ) {
    margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
	width: 44px;
	background-position: 0 0;
	background-color: #2E5CF2;
}

input[type="checkbox"].switch + div {
    width: 44px;
    height: 23px;
}


input[type="checkbox"].switch:checked + div {
	background-color: #2E5CF2;
    ;
}

input[type="checkbox"].switch + div > div {
    float: left;
    width: 21px;
    height: 21px;
    border-radius: inherit;
    background: #ffffff;
    transition-timing-function: cubic-bezier(1,0,0,1);
    transition-duration: 0.4s;
    transition-property: transform, background-color;
    pointer-events: none;
    margin-top: 1px;
    margin-left: 1px;
}


input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(10px, 0, 0);
	transform: translate3d(10px, 0, 0);
	background-color: #ffffff;
}

input[type="checkbox"].bigswitch.switch + div > div {
	width: 44px;
	height: 23px;
	margin-top: 1px;
}


input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(21px, 0, 0);
	transform: translate3d(21px, 0, 0);
}
