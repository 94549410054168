@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://unpkg.com/aos@2.3.1/dist/aos.css);
body {
  font-family: "Montserrat", sans-serif;
  background: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
}
/* CSS Document */
* {
  padding: 0;
  margin: 0;
  list-style: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}
/* remember to define focus styles! */
:focus {
  outline: 0;
}
ol,
ul {
  list-style: none;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
blockquote,
q {
  quotes: "" "";
}

input {
  background: none;
  border: 0 none;
  outline: none;
}
table {
  border: 0 none;
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  vertical-align: top;
}
img {
  border: 0 none;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  display: block;
  margin: 0 auto;
}
a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
/*responsive menu*/
.menu {
  color: #333333;
  cursor: pointer;
  float: left;
  font-family: "Lato", sans-serif;
  font-size: 22px;
  line-height: 31px;
  display: none;
}
.menu .menu-trigger {
  float: left;
  margin: 8px 20px 0 25px;
}
.menu .line:nth-child(1):after {
  transition: all 0.6s linear 0.1s;
}
.menu .line:after {
  background: #ffffff;
  content: "";
  display: block;
  height: 3px;
  width: 0;
}
.menu .line {
  background: #333333;
  display: block;
  height: 3px;
  margin-bottom: 5px;
  width: 33px;
}
.header-sec .menu-wrap .menu:hover .line:after {
  transform: rotate(360deg);
  width: 100%;
}
.show-menu .side-menu {
  transition: all 1.4s ease 0s;
  z-index: 1;
}
.side-menu {
  display: none;
  background: #333333;
  height: 100%;
  overflow: hidden;
  padding: 69px 50px 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.4s ease 0s;
  width: 225px;
  z-index: -1;
}
.show-menu #wrapper {
  transform: translateX(-325px);
}
#wrapper {
  background: #ffffff;
  position: relative;
  transition: all 0.3s ease 0s;
  /* z-index: 99999; */
}
/*responsive menu end */
.container {
  margin: 0 auto;
  max-width: 1110px;
  width: 100%;
}
.btn {
  background: #b27803 !important;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: center;
  color: #ffffff;
  border: 2px solid transparent;
  font-family: "Montserrat", sans-serif;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  padding: 8px 20px;
}
.autocomplete-dropdown-container {
  position: absolute;
  top: 90px;
  width: 98%;
}
.autocomplete-dropdown-container .fa,
.autocomplete-dropdown-container span {
  padding: 2px 5px;
}

.btn:hover {
  background: none !important;
  color: #b27803;
  border: 2px solid #b27803;
}

.btn-primary {
  background: #78b06e;
}
.btn-secondary {
  background: #335480;
}
.title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 123.3%;
  color: #b27803;
}

p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 172.96%;
  color: #4f4f4f;
}
/**************** Header ****************/
.inner-header .login-header-menu {
  padding: 0 50px;
  margin-top: 10px;
}
.inner-header .login-header-menu a {
  text-decoration: none;
}
.login {
  background: url(/static/media/nurse.546de2a1.png) no-repeat !important;
  background-position: top right !important;
  height: 100vh;
}
.user-login {
  background: url(/static/media/patienttest.719c5089.svg) no-repeat !important;
  background-position: top right !important;
  height: 100vh;
}
.forgot-login {
  background: url(/static/media/forgetlogin.73ea63c2.svg) no-repeat !important;
  background-position: top right !important;
  height: 100vh;
}

.title-block {
  margin-bottom: 10px !important;
}
#header {
  padding-top: 30px;
}
.logo a {
  /* width: 20%; */
  display: block;
  text-align: left;
}
.logo a img {
  display: inline-block;
}
.login-left {
  margin-top: 70px;
}
.login-left .title {
  margin-bottom: 45px;
}
.form-field-wrapper label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
  display: block;
  margin-bottom: 13px;
}
.form-field-wrapper .txt {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 330px;
  height: 50px;
  width: 100%;
  padding-left: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #232ed1;
}
.form-field.full {
  margin-bottom: 30px;
}

.form-field-wrapper .txt:focus {
  background: #ffffff;
  border: 1px solid #2e5cf2;
  box-sizing: border-box;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.btn-wrapper .btn {
  max-width: 330px;
  height: 55px;
  width: 100%;
  margin-top: 10px;
}
.notlogin {
  margin-top: 40px;
  max-width: 330px;
  width: 100%;
  text-align: end;
}
.notlogin p {
  display: flex;
  justify-content: center;
}
.notlogin p a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #2e5cf2;
  margin: 0 17px;
  transition: 0.3s all ease-in-out;
  position: relative;
}
.notlogin p a:hover {
  color: #b27803;
}
.notlogin p a:first-child:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 2px;
  background: #2e5cf2;
  right: -17px;
  top: 8px;
  border-radius: 50%;
}
.txt::-webkit-input-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #bfbfbf;
}
.txt::-moz-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #bfbfbf;
}
.txt:-ms-input-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #bfbfbf;
}
.txt:-moz-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #bfbfbf;
}
.pass {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAQCAYAAAAS7Y8mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHBSURBVHgBhVTRcYJAEL1jLMAS6CBaATAWEK1ArEBTAVBBTAVCBTHfjoNWEEogHdCAmvdkcY7zSHZm52Bv9+3uu73TakDKshxfr9fY87xX/PqilAZaYe8Le/soimpXvHYBYnmHxtBaACp+iwsThkbCHJrZCXrAh8MhHI1Gn6xInE/qD0ERIZad/L7Bf/8EDKelkT21kiX4nLBa6AkVF7PZLDdi6Z+gizgIgoI2TzYmLlDYE4CWQsMKurjdbj8A2HGv85OYDAlz6UJp4fQbWpigx+ORB8c2V7Dnqk9BLBREJl2I2Qr3U1a8NrI+BA53amxQ8aWNfCZWzB0DlW8IHLMN9SwhHM5qQDgtquXdTNjA/oEEawJzfMaO2Eb9L0NxjddlEK5NqYQvp2AvwHIybcSAnfQUWL2t2BMrlvTMcSAbG1RGMyaAtdVhbLU4hlygKXjKDIBUnPOOb+lirhyjyXjoFPbKvCCxakfIBqedFYZK3gll3Ur48AlgZ4/R1FaLrIROPPXMvF0ukVtJfx+6MJO5HiEftyvVWrPKGrq/XC5n2Bqhwoe+CB0EzFX7TvSmSKsBYQJpf6n6z2YtyjnObcBOfgGFeOiL3HNigAAAAABJRU5ErkJggg==) no-repeat !important;
  background-position: right 10px center !important;
}

/*****Privacy****/
.privacy{
  background-position: top right !important;
  min-height: 100vh;
  padding-bottom: 63px;
  font-weight: bold;
  line-height: 10;
}

.contactUs{
  border: 5px solid blueviolet;
  padding: 50px;
}

/********* Register *********/


.register, .user-register {
  background: url(/static/media/picture.ce2d7fa3.jpg) no-repeat !important;
  background-position: top right !important;
  min-height: 100vh;
  padding-bottom: 63px;
}
.user-register {
  background: url(/static/media/registerimg.6aa64c8e.svg) no-repeat !important;
  background-position: top right !important;
  min-height: 100vh;
  padding-bottom: 63px;
}

.refer-user{
  background: url(/static/media/picture.ce2d7fa3.jpg) no-repeat !important;
  background-position: top right !important;
  min-height: 100vh;
  padding-bottom: 63px;
}

.register .login-left .title {
  margin-bottom: 10px;
}
.login-link {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 173.3%;
  color: #4f4f4f;
}
.login-link a {
  color: #2e5cf2;
  display: inline-block;
  transition: 0.3s all ease-in-out;
}
.login-link a:hover {
  color: #b27803;
}
.register-form {
  margin-top: 50px;
}
.register .form-field-wrapper {
  max-width: 710px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}
.form-field.uploadfile {
  max-width: 350px;
  border: 1px dashed #dedede;
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.register .login-left {
  margin-top: 0px;
}
.form-field.uploadfile p {
  display: block;
  width: 100%;
  position: absolute;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-field.uploadfile p span:first-child {
  margin-right: 10px;
}
.form-field.uploadfile p span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
}
input.fu {
  position: relative;
  opacity: 0;
  height: 88px;
  width: 100%;
  cursor: pointer;
}
.uploaded-image {
  position: absolute;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
}
.form-field-wrapper .form-field {
  width: 47%;
}
.form-field select {
  height: 55px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #232ed1;
  padding-left: 10px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgBfc5hDYAgEAVgTwoYgQhG0AbYQBtoAxtoA2cDRgGrUIThO+cPQPC2G8573wEppaT3/iCiSWttq0Ih1yK3ITfUDPCvw3nxgh9wcQ4913wDPixa5mAAGpwnXrPSO5DvgIHFop6fmgJjzMh5CjZG0Dm3CCGOFEQoA59KwQelMAeKxRC9l+Y3WUBpGJqJvjEAAAAASUVORK5CYII=) no-repeat right 21px center;
  background-color: #fff;
}
.form-field select option {
  color: #4f4f4f;
}
.form-field select:focus {
  border: 1px solid #2e5cf2;
}
.form-field.form-title select {
  width: 160px;
}
.form-field.uploadfile {
  width: 49%;
}
.modal-90w {
  width: 980px;
  max-width: none !important;
}

.inner-header.main-header {
  display: flex;
  justify-content: space-between;
}
.main-header .logo {
  width: 40%;
}
.main-header .logo a img {
  width: 75px;
}
.header-right {
  display: flex;
  width: 60%;
  justify-content: flex-start;
}
.header-right-left ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-right-left ul li {
  margin-right: 26px;
}
.btn-secondary {
  border: 2px solid #2e5cf2;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  padding: 0px 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #2e5cf2;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease-in-out;
}
.btn-secondary p {
  margin-right: 14px;
}
a.btn-secondary:hover {
  background: #2e5cf2;
  color: #fff;
}
a.btn-secondary:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.current-user {
  display: flex;
  align-items: center;
}
.header-right-right {
  width: 48%;
}
.current-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dropdown-menu-arrow {
  margin-left: 65px;
}
.user-image {
  margin-right: 15px;
  border-radius: 50%;
}
span.user-image img {
  border-radius: 50%;
}
.username h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 123.3%;
}
.current-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left: 1px solid #bdc2d5;
}
.header-right-left {
  width: 50%;
}
.dropdown-menu {
  width: 100%;
  background: #b27803;
  margin-top: 15px;
  display: none;
  padding: 0;
}
.dropdown-menu a {
  padding: 10px;
  border-bottom: 1px solid;
  color: #fff;
  font-weight: 600;
  transition: 0.5s all ease-in-out;
}
.dropdown-menu ul {
  color: #fff;
  text-align: center;
}
.dropdown-menu a:hover {
  background: #444;
  text-decoration: none;
}
.dashboard .inner-header.main-header {
  padding-bottom: 35px;
}
.filter-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-left {
  width: 40%;
}
.filter-right {
  width: 60%;
}
.filter-right-inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.view button {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;
  height: 45px;
  width: 45px;
  margin-right: -5px;
}
.view {
  margin-right: 5px;
  margin-left: 20px;
}
.sel {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
  width: 155px;
  margin-left: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
  padding-left: 5px;
}
.search-form {
  display: flex;
  align-items: center;
}
.btn-search {
  border: none;
  background: none;
  order: -1;
  width: 24px;
  height: 24px;
  margin-right: 13px;
}
.txtsearch {
  width: 75px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 123.3%;
  color: #4f4f4f;
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid transparent;
  height: 45px;
}
.txtsearch:focus {
  width: 150px;
  border-bottom: 1px solid #dedede;
}
.filter-table {
  margin-top: 30px;
  margin-bottom: 5%;
}
.filter-table ul {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 6px 0;
  margin-bottom: 10px;
  align-items: center;
  border: 1px solid transparent;
  transition: 0.2s all ease-in-out;
}
.filter-table ul:hover {
  border: 1px solid #2e5cf2;
  box-sizing: border-box;
  box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  position: relative;
  z-index: 999;
}
.filter-table ul li {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 172.96%;
  color: #4f4f4f;
  width: 12.5%;
  word-break: break-all;
}
.filter-table ul li:first-child {
  width: 5%;
}
.filter-table ul li:nth-child(2) {
  width: 12.5%;
}
.filter-table ul li:last-child {
  width: 2%;
}
.filter-table ul li:nth-child(6),
.filter-table ul li:nth-child(7) {
  width: 18%;
}
.filter-title li {
  color: #b27803 !important;
}
.filter-table ul li img {
  border-radius: 50%;
  margin-left: 5px;
}
.filter-table ul:hover li:nth-child(6),
.filter-table ul:hover li:nth-child(5) {
  color: blue;
}
.clickable {
  width: 5px;
  margin-right: 0;
  height: 20px;
  cursor: pointer;
}
.clickable span {
  display: block;
  background: #bdc2d5;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-bottom: 4px;
}
.filter-table ul:hover li:last-child span {
  background: #2e5cf2;
}
/******* Contact Model ********/
.contact-model {
  width: 980px;
  background: #ffffff;
  border: 1px solid #eff2f6;
  box-sizing: border-box;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 50px 95px;
  text-align: center;
}
.conatc-overview {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #4f4f4f;
  margin-bottom: 30px;
}
.contact-form {
  display: flex;
  justify-content: space-between;
}
.contact-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}
.contact-form-wrapper label {
  width: 100%;
}
.contact-form-left {
  width: 47%;
}
.contact-txt {
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  margin-top: 10px;
  padding-left: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #666;
}
.contact-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 26px;
}
.contact-form-right {
  width: 47%;
}
.contact-form-wrapper textarea {
  resize: none;
  margin-top: 10px;
  height: 276px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #666;
  padding-top: 10px;
}
.contact-model .btn-secondary {
  background: #2e5cf2;
  border-radius: 5px;
  height: 55px;
  margin: 20px auto 0;
  width: 260px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  border: 2px solid #2e5cf2;
  transition: 0.3s all ease-in-out;
}
.contact-model .btn-secondary:hover {
  background: #fff;
  color: #2e5cf2;
}
select.contact-txt {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAICAYAAAAiJnXPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgBfc5hDYAgEAVgTwoYgQhG0AbYQBtoAxtoA2cDRgGrUIThO+cPQPC2G8573wEppaT3/iCiSWttq0Ih1yK3ITfUDPCvw3nxgh9wcQ4913wDPixa5mAAGpwnXrPSO5DvgIHFop6fmgJjzMh5CjZG0Dm3CCGOFEQoA59KwQelMAeKxRC9l+Y3WUBpGJqJvjEAAAAASUVORK5CYII=) no-repeat right 10px center;
}
.patient textarea {
  height: 163px;
}
.patient input.btn {
  width: 260px;
  height: 55px;
  margin-top: 30px;
}
.patient .section-title {
  color: #b27803;
}
.contact-model.patient {
  padding: 105px 95px;
}
/********* Landing Page *******/
.landing .login-left .title {
  margin-bottom: 45px;
  max-width: 481px;
  font-weight: bold;
}
.landing .login-left span {
  color: #2e5cf2;
}
.inner-header {
  display: flex;
  padding-bottom: 80px;
}
.header-left {
  width: 50%;
}
.header-right {
  display: flex;
  width: 53%;
  justify-content: flex-start;
}
.header-menu ul {
  display: flex;
  justify-content: flex-start;
}
.header-menu ul li a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 173.3%;
  color: #4f4f4f;
  margin-right: 35px;
}
.header-menu ul li.active a {
  color: #2e5cf2;
}
.header-menu ul li.down a {
  color: #b27803;
  text-transform: uppercase;
}
.header-image {
  display: flex;
  margin-top: 92px;
}
.header-left .login-left {
  margin-top: 100px;
}
.black-btn a {
  border: 3px solid #4f4f4f;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  max-width: 260px;
  height: 55px;
  justify-content: center;
  align-items: center;
}
.black-btn a span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #4f4f4f;
  transition: 0.3s all ease-in-out;
}
.black-btn a:hover {
  background: #4f4f4f;
}
.black-btn a:hover span {
  color: #fff;
}
.black-btn a span:nth-child(2) {
  margin-left: 15px;
}
.black-btn a:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  transition: 0.3s all ease-in-out;
}
.black-btn a span:first-child {
  margin-left: -15px;
  margin-right: 15px;
  margin-top: -5px;
}
.landing #header {
  border-bottom: 1px solid #eff2f6;
}
.our-vision-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 123.3%;
  color: #2e5cf2;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.our-vision-sec p {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #4f4f4f;
  max-width: 754px;
  margin: 0 auto;
}
.vision-box-wrapper {
  display: flex;
  padding-bottom: 50px;
}
.vision-box {
  width: calc(100% / 4 - 30px);
  margin: 0 15px;
  text-align: center;
}
span.vision-box-image {
  display: inline-block;
  margin-bottom: 10px;
  min-height: 75px;
}
.vision-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #2e5cf2;
  margin-bottom: 20px;
}
.choose-doctor {
  background: #e4e6ea;
  border: 1px solid #eff2f6;
  padding: 60px 0;
}
.choose-inner {
  display: flex;
  align-items: center;
}
.choose-left,
.choose-right {
  width: 25%;
}
.choose-center {
  width: 50%;
  position: relative;
}
.choose-figur-box {
  margin-bottom: 80px;
}
.choose-left .choose-figur-box {
  text-align: right;
}
.choose-figur-box h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 123.3%;
  text-align: right;
  color: #b27803;
}
.choose-right .choose-figur-box h2 {
  text-align: left;
}
.choose-figur-box {
  position: relative;
}
.choose-figur-box:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 80px;
  border-bottom: 1px dashed #bdc2d5;
  right: -91px;
  bottom: 12px;
}
.choose-right .choose-figur-box:after {
  right: auto;
  left: -91px;
}
.img-content {
  width: 270px;
  text-align: center;
  position: absolute;
  bottom: 125px;
  left: 0;
  right: 0;
  margin: auto;
}
.choose-figur-box h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 123.3%;
  color: #4f4f4f;
  margin-top: 7px;
}
.img-content h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 123.3%;
  color: #000;
  margin-top: 7px;
  margin-bottom: 10px;
}
.img-content h5 span {
  display: block;
}
.img-content p {
  line-height: 1.5;
  font-size: 13px;
  color: #999;
}
.choose-doctor-btn {
  text-align: center;
  margin-top: 50px;
}
.choose-doctor-btn .btn {
  display: inline-block;
  min-width: 260px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
}
.choose-doctor-btn .btn:hover {
  background: #fff;
  color: #b27803;
}
.feedback {
  padding: 50px 0;
}
.feedback-rating {
  display: flex;
  max-width: 108px;
  margin-bottom: 15px;
}
.feedback-quotes p {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #4f4f4f;
}
.feedback-author {
  display: flex;
  align-items: center;
  margin-top: 14px;
  justify-content: flex-end;
}
.author-image {
  margin-right: 10px;
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.feedback-author h6 {
  text-align: right;
}
.feedback-quotes {
  padding: 30px;
  border: 1px solid #e4e6ea;
  position: relative;
  min-height: 210px;
}
.feedback-quotes:before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #e4e6ea transparent transparent transparent;
  border-width: 13px;
  right: 57px;
  bottom: -26px;
}
.feedback-quotes:after {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: white transparent transparent transparent;
  border-width: 13px;
  right: 57px;
  bottom: -24px;
}
.feedback-slider {
  display: flex;
  justify-content: space-between;
}
.feebackbox {
  width: 49%;
}
.contact-us {
  position: relative;
}
.contact-us-inner {
  display: flex;
}
.contact-us-left,
.contact-us-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 50px 0;
  position: relative;
  z-index: 9;
}
.contact-us:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  left: 0;
  background: #2e5cf2;
  top: 0;
}
.contact-us:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  right: 0;
  background: #b27803;
  top: 0;
}

.contact-us-left h3,
.contact-us-right h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 35px;
  line-height: 172.96%;
  color: #ffffff;
  margin-top: 18px;
}
.contact-btn {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  min-width: 350px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #2e5cf2;
  border: none;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.contact-btn:hover {
  opacity: 0.8;
}
.contact-btn-wrap {
  margin-top: 40px;
}
.contact-us-right .contact-btn {
  color: #b27803;
}

.footer {
  background: #172f7b;
  padding: 25px 0;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.footer-top-center a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 0 37px;
  display: block;
  transition: 0.3s all ease-in-out;
}
.footer-top-center ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-top-right ul {
  display: flex;
  justify-content: flex-end;
}
.footer-top-right ul a {
  padding: 0 18px;
  color: #fff;
  font-size: 25px;
  transition: 0.3s all ease-in-out;
}
.footer-top-right ul a:hover,
.footer-top-center a:hover {
  opacity: 0.8;
}
.footer-top-left .btn {
  display: flex;
  height: 46px;
  align-items: center;
  width: 174px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  justify-content: space-around;
}
.footer-top-left .btn img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.footer-top-left {
  width: 25%;
  text-align: left;
}
.footer-top-center {
  width: 50%;
  text-align: center;
}
.footer-top-right {
  width: 25%;
  text-align: right;
}
.footer-bottom {
  margin-top: 14px;
}
.footer-bottom p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #bdc2d5;
  mix-blend-mode: normal;
  opacity: 0.35;
  text-align: center;
}
.dashboard-top {
  background: #2e5cf2;
}
.dashboard-top .container {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
}
.dahsboard-top-left {
  display: flex;
  align-items: center;
}
.dahsboard-top-left p,
.dahsboard-top-right p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #ffffff;
  margin-left: 20px;
}
.dahsboard-top-left img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}
.dahsboard-top-right {
  display: flex;
  align-items: center;
}
.dahsboard-top-left .hide-btn {
  cursor: pointer;
  /* text-decoration: underline; */
}
.dashboard-footer .hide {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.dashboard-footer {
  height: 100%;
  background: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
}
button.contact-btn:focus {
  outline: none;
}
.provider-pagination {
  margin-bottom: 0px !important;
}
.Pagination .hidden {
  display: none;
}
.provider-pagination .pagination {
  margin-right: 0px !important;
}
.provider-pagination .last-page {
  display: flex;
}
.provider-pagination .last-page a{
  margin-left: 10px;
}
.provider-pagination .prev-page {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  -webkit-user-select: none; /* Safari */ /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}


.provider-pagination .next-page {
  margin-left: 30px;
  font-size: 14px;
  font-weight: 500;
  -webkit-user-select: none; /* Safari */ /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.Pagination span:nth-child(2) {
  display: flex;
  margin: 0 5px;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin-right: -40px;
}
.pagination a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 172.96%;
  color: #4f4f4f;
  padding: 0 10px;
}
.Pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
}

.Pagination span:nth-child(2) a {
  margin-left: 15px;
  margin-right: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 172.96%;
  color: #4f4f4f;
  padding: 0 3px;
}
.pagination a:hover,
.Pagination span:nth-child(2) a:hover,
.pagination li.active a {
  color: #b27803;
  text-decoration: none;
}
.btn-secondary:hover {
  background: #2e5cf2;
  color: #fff;
  border-color: #2e5cf2;
  text-decoration: none;
}
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #315ce8;
  border-color: #315ce8;
  box-shadow: none;
}
.view button:focus {
  outline: none;
}
.view button:hover {
  outline: #315ce8;
}
.user-profile-wrapper {
  position: relative;
  cursor: pointer;
}
span.profile-edit {
  float: right;
  border-radius: 0;
}
span.profile-edit img {
  border-radius: 0;
}
.img-close-btn {
  position: absolute;
  top: 2px;
  right: 100px;
  background: #ffffff;
  border-radius: 50%;
  border: 0.5px solid;
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  cursor: pointer;
}
/********* Profile ************/
/****** Open one *****/
.openone-title .title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 123.3%;
  color: #b27803;
}
.openone-title span {
  color: #2e5cf2;
  font-size: 15px;
  font-weight: 600;
}
.profile-wrapper {
  display: flex;
  margin-top: 30px;
}
.profile-left {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  text-align: center;
  color: #4f4f4f;
  width: 40%;
  padding: 25px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.profile-right {
  width: 60%;
  padding-left: 30px;
}
.profile-right select {
  width: 100%;
  float: left;
  margin: 0;
  height: 45px;
  border: none;
  border-bottom: 1px solid;
}

.profile-right input,
.profile-left input {
  border-bottom: 1px solid black;
  width: 100%;
}
.profile-right .edit-btn {
  text-align: center;
}
.profile-right ul {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  padding: 18px;
}
.profile-right ul li:first-child {
  width: 25%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #b27803;
}
.profile-right ul li:nth-child(2) {
  width: 60%;
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
  margin-left: 5%;
}
.profile-right ul li:nth-child(3) {
  width: 10%;
}
.doctor-name {
  margin-top: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 172.96%;
  color: #4f4f4f;
  margin-bottom: 13px;
}
.doctor-email {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #2e5cf2;
  margin-bottom: 20px;
}
.doctor-address p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  text-align: center;
  color: #4f4f4f;
  max-width: 264px;
  margin: 10px auto 0;
}
.profile-btn-wrapper {
  padding-top: 60px;
  display: flex;
  justify-content: flex-end;
}

.profile-btn-wrapper .btn-save {
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2e5cf2;
  min-width: 220px;
  height: 45px;
  margin-left: 15px;
  border: 2px solid #2e5cf2;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.profile-btn-wrapper .btn-save span:first-child {
  margin-right: 15px;
}
.profile-btn-wrapper .btn-save:hover {
  background: #2e5cf2;
  color: #fff;
}
.profile-btn-wrapper .btn-save:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  transition: 0.3s all ease-in-out;
}
.profile-left img {
  border-radius: 50%;
}
.btn-del {
  min-width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #4f4f4f;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  margin-right: 25px;
}
.btn-del:hover {
  opacity: 0.8;
}
.btn-del span:first-child {
  margin-right: 15px;
}
.popover-content.popover-body {
  width: 150px;
  background: #ffffff;
  border: 3px solid #b27905;
  box-sizing: border-box;
  box-shadow: 0px 4px 44px rgba(185, 185, 205, 0.6);
}
.popover-content h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  margin: 15px;
  text-align: center;
}
.popover-content h3 span {
  font-size: 16px;
  cursor: pointer;
  color: #2e5cf2;
  font-weight: 600;
}
/* Error */
.contact-form-wrapper .error {
  border: 2px solid red;
}
.register .form-field-wrapper .error input, .user-register .form-field-wrapper .error input, 
.register .form-field-wrapper .error select {
  border: 1px solid red;
}
.img-preview {
  overflow: hidden;
}
.loader {
  justify-content: center !important;
  height: 100px;
}
.register-form {
  position: relative;
}
.div-loading:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
  left: 0;
}
.div-loading {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 66%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
}
.div-loading li {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
.physician-img {
  height: 125px;
  width: 125px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}
input.img_upload {
  position: absolute;
  left: 0;
  height: 125px;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 5;
}
.physician-img .overlay {
  height: 125px;
  position: absolute;
  background: #0000006b;
  width: 125px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

/********** New password *********/

.code-container {
  width: 400px;
  display: flex;
}
.code-container input {
  width: 70px !important;
  height: 50px !important;
}

.code-container input::-webkit-outer-spin-button,
.code-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.code-container input[type=number] {
  -moz-appearance: textfield;
}

/******* 02-07-2021 **********/
.form-bottom {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  justify-content: center;
  text-align: center;
}
.form-bottom span {
  margin: 10px 0 10px;
  font-size: 16px;
  line-height: 172%;
  text-transform: lowercase;
}
.btn-facebook {
  display: flex;
  align-items: center;
  background: #ededfd;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.btn-facebook:hover {
  background: #2e5cf2;
}
.btn-facebook label {
  color: #2E5CF2;
  font-weight: 600;
  margin-left: 30px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.btn-facebook:hover label{
  color: #fff;
}

.btn-facebook:hover .facebook-icon
{
  background: #fff;
  color: #2e5cf2 !important;
}
.facebook-icon {
  color: #fff !important;
  font-size: 15px;
  cursor: pointer;
  background: #2E5CF2;
  padding: 7px 11px;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
}


/********* Responsive *********/
@media (max-width: 1169px) {
  .container {
    width: 96%;
  }
  .login {
    background-size: 62% !important;
  }
  .header-image {
    display: flex;
    margin-top: 92px;
    width: 100%;
    overflow: hidden;
  }
  .header-image img {
    width: 25% !important;
  }

  /******* Regiter Page ********/
  .register,
  .login {
    background: none !important;
  }
  .login-left {
    margin-top: 10px;
  }
  .register .form-field-wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
  .form-field-wrapper .txt {
    max-width: 100% !important;
  }
  .form-field.uploadfile {
    max-width: 100% !important;
  }
}
@media (max-width: 1100px) {
  .header-menu ul li a {
    margin-right: 20px !important;
  }
}

@media (max-width: 1024px) {
  .header-menu ul li a {
    margin-right: 11px !important;
    font-size: 14px !important;
  }
  .logo {
    width:auto;
  }
  .header-right {
    width: 75%;
    display: flex;
    justify-content: flex-end;
}
.header-right-left {
  width: auto;
}
.header-right-right {
  width: auto;
}
.header-right-left ul li {
  margin-right: 10px;
}
.current-user {
  padding-left: 10px;
}
}
@media (max-width: 991px) {
  .header-right {
    width: 60%;
  }
  .header-menu ul li a {
    margin-right: 5px !important;
    font-size: 13px !important;
  }
  .logo {
    width: 60%;
  }
  .vision-box-wrapper {
    flex-wrap: wrap;
  }
  .vision-box {
    width: calc(100% / 2 - 30px) !important;
    margin: 0 15px 30px !important;
  }
  .choose-left {
    width: 25%;
    padding-right: 13px;
  }
  .choose-right {
    padding-left: 8px;
  }
  .choose-right .choose-figur-box:after {
    display: none;
  }
  .contact-us-left h3,
  .contact-us-right h3 {
    font-size: 26px !important;
  }
  .contact-btn {
    min-width: 250px !important;
  }
  .footer-top-center a {
    padding: 0 15px !important;
  }
  .notlogin {
    margin-top: 40px;
    max-width: 100%;
    width: 100%;
  }
  /****** Dashboard *******/
  .dropdown-menu-arrow {
    margin-left: 24px;
  }
  .main-header .logo {
    width: 25%;
  }
  .header-right {
    width: 75%;
  }
  .btn-secondary {
    padding: 0px 10px;
  }
  .inner-header.main-header {
    padding-bottom: 40px !important;
  }
  .filter-table ul {
    width: 1200px !important;
  }
  .filter-table {
    margin-top: 30px;
    overflow-x: auto !important;
  }
  .filter-table ul li:nth-child(2) {
    width: 12.5%;
  }
  #section {
    min-height: 500px !important;
  }
  .dashboard-footer .hide {
    bottom: 0;
    position: relative !important;
    width: 100%;
  }
  .modal-90w {
    width: auto;
    max-width: none !important;
  }
}

@media (max-width: 767px) {
  .inner-header .login-header-menu {
    padding: 0 !important;
    margin-top: 10px;
  }
  .inner-header {
    padding-bottom: 50px !important;
    flex-direction: column;
  }
  .our-vision-sec p {
    font-size: 15px !important;
  }
  .header-left {
    width: auto;
  }
  .header-left {
    width: 100% !important;
    text-align: center !important;
  }
  .logo a {
    display: block !important;
    text-align: center !important;
    width: 100% !important;
  }
  .logo a {
    text-align: left !important;
    }
  .logo a img {
    display: inline-block !important;
    width: 17% !important;
  }
  .header-left .login-left {
    margin-top: 30px !important;
  }
  .header-image {
    display: flex;
    margin-top: 50px !important;
    width: 100% !important;
  }
  .header-right {
    width: 100% !important;
  }
  .black-btn a {
    margin: 0 auto !important;
  }
  .vision-title {
    margin-bottom: 10px !important;
  }
  .choose-inner {
    display: flex;
    align-items: center !important;
    flex-direction: column !important;
  }
  .choose-left,
  .choose-right {
    width: 100% !important;
  }
  .choose-figur-box {
    margin-bottom: 50px !important;
  }
  .choose-left .choose-figur-box {
    text-align: center !important;
  }
  .choose-figur-box h2 {
    text-align: center !important;
  }
  .choose-right .choose-figur-box h2 {
    text-align: center !important;
  }
  .choose-figur-box h4 {
    text-align: center !important;
  }
  .choose-center {
    width: 100% !important;
    position: relative;
    margin-bottom: 30px !important;
  }
  .choose-figur-box {
    margin-bottom: 25px !important;
    padding-bottom: 25px !important;
    border-bottom: 1px dashed #ccc;
  }
  .choose-figur-box:after {
    display: none !important;
  }
  .feebackbox {
    width: 100% !important;
  }
  .contact-us-left h3,
  .contact-us-right h3 {
    font-size: 18px !important;
  }
  .contact-btn {
    min-width: 150px !important;
    height: 40px !important;
  }
  .footer-top {
    flex-direction: column;
  }
  .footer-top-left {
    width: auto !important;
    text-align: left;
    margin-bottom: 30px;
  }
  .footer-top-center {
    width: 100% !important;
    text-align: center;
    order: -1;
    margin-bottom: 30px;
  }
  .footer-bottom {
    margin-top: 14px;
  }
  .footer-top-right ul {
    display: flex;
    justify-content: center !important;
  }
  .contact-btn-wrap {
    margin-top: 20px;
  }
  li.down {
    display: none;
  }

  .landing .login-left .title {
    margin-bottom: 45px;
    max-width: 100% !important;
  }
  .header-menu ul {
    justify-content: center !important;
    margin-top: 20px !important;
  }
  .logo a img {
    display: inline-block !important;
    width: 20% !important;
  }
  .header-menu ul li a {
    margin-right: 35px !important;
    font-size: 24px !important;
  }
  .choose-doctor-btn {
    text-align: center !important;
    margin-top: 30px !important;
  }
  .landing .login-left .title {
    margin-bottom: 25px !important;
  }
  /***** Regiter Page ******/
  .register .login-left .title {
    margin-bottom: 10px;
    text-align: center;
  }
  .login-link {
    text-align: center;
  }
  .register .form-field-wrapper {
    max-width: 100% !important;
    width: 100% !important;
    flex-direction: column;
    margin-bottom: 0 !important;
  }
  .form-field-wrapper .form-field {
    width: 100%;
    margin-bottom: 15px;
  }
  .form-field.form-title select {
    width: 100%;
  }
  .btn-wrapper .btn {
    max-width: 100% !important;
  }
  .notlogin p {
    flex-direction: column !important;
    text-align: center !important;
  }
  .notlogin p a {
    margin: 0 17px 10px !important;
  }
  .profile-wrapper {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
  }
  .profile-left {
    width: 100% !important;
    margin-bottom: 30px !important;
  }
  .profile-right {
    width: 100% !important;
    padding-left: 0 !important;
  }
  .header-right-left ul li {
    margin-right: 15px;
  }
  .header-right-left ul li span {
    font-size: 14px;
  }
  .main-header .logo {
    margin-bottom: 30px !important;
  }
  .profile-btn-wrapper {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .btn-del {
    min-width: 0 !important;
    min-width: initial !important;
  }
  .openone-title .title {
    font-size: 22px !important;
  }
  .feedback-slider {
    flex-direction: column !important;
  }
  .feedback-quotes {
    margin-top: 30px !important;
    min-height: 0 !important;
  }
  .login-link {
    font-size: 15px;
    text-align: left !important;
}
.form-field-wrapper label {
  font-size: 14px;
}
.header-differ .inner-header.main-header {
  flex-direction: row;
}
.header-differ .inner-header.main-header .header-right {
  flex-direction: row;
}
.header-differ .inner-header.main-header .logo {
  width: 100px;
}
.header-differ .inner-header.main-header .logo a img {
  width: 90% !important;
}
.header-differ .inner-header.main-header .dropdown-menu-arrow {
  margin-left: 0;
}
.notification .btn-secondary {
  padding: 0 5px;
}
.notification .btn-secondary p {
  margin-right: 4px;
}
span.user-image img {
  border-radius: 50%;
  height: 36px !important;
}
.header-right-left ul li {
  margin-right: 5px;
  margin-top: 0px;
}
}

@media (max-width: 480px) {
  .landing .login-left .title {
    margin-bottom: 25px !important;
    max-width: 100% !important;
  }
  .title {
    font-size: 28px !important;
  }
  .header-left .login-left {
    margin-top: 25px !important;
  }
  .logo a img {
    display: inline-block !important;
    width: 25% !important;
  }
  .title {
    font-size: 20px !important;
  }
  .black-btn a {
    max-width: 240px !important;
    height: 47px !important;
  }
  .header-image {
    margin-top: 20px !important;
  }
  .inner-header {
    padding-bottom: 30px !important;
  }
  .our-vision-sec {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .our-vision-sec p {
    font-size: 14px !important;
    line-height: 150% !important;
  }
  .vision-box {
    width: 100% !important;
    margin: 0 0px 30px !important;
  }
  .img-content {
    width: 260px !important;
    bottom: 85px !important;
  }
  .choose-doctor-btn {
    margin-top: 20px !important;
  }
  .contact-us-inner {
    display: flex !important;
    flex-direction: column !important;
  }
  .contact-us:before {
    height: 50% !important;
    width: 100% !important;
  }
  .contact-us:after {
    height: 50% !important;
    width: 100% !important;
    top: 50% !important;
  }
  .contact-us-left,
  .contact-us-right {
    width: 100% !important;
  }
  .contact-us-left h3,
  .contact-us-right h3 {
    font-size: 28px !important;
  }
  .contact-btn-wrap {
    margin-top: 20px !important;
  }
  .footer-top-center a {
    padding: 0 10px !important;
  }
  .header-right {
    width: 100% !important;
    flex-direction: column;
  }
  .header-right-left {
    width: 100%;
    margin-bottom: 20px;
  }
  .header-right-right {
    width: 100%;
  }
  .current-user {
    justify-content: flex-end !important;
    border-left: none !important;
  }
  .openone-title .title {
    font-size: 17px !important;
  }
  .profile-right ul {
    flex-direction: column !important;
  }
  .profile-right ul li:first-child {
    width: 100% !important;
  }
  .profile-right ul li:nth-child(2) {
    width: 100% !important;
    margin: 15px 0 !important;
  }
  .profile-btn-wrapper {
    flex-direction: column;
  }
  .profile-btn-wrapper .btn-del {
    margin-right: 0;
    order: 2;
    margin-top: 20px;
  }
  .profile-btn-wrapper .btn-save {
    margin-left: 0 !important;
  }
  .header-right-left ul {
    justify-content: center;
  }
  .dahsboard-top-right {
    display: none !important;
  }
  .header-menu ul li a {
    margin-right: 10px !important;
    font-size: 17px !important;
  }
  .user-register,.user-login {
    background-position: bottom right !important;
    background-size: 309px 347px !important;
  }
  .code-container {
    width: auto;
    display: flex;
}
.code-container input {
  width: 57px !important;
  height: 50px !important;
}
span.username {
  display: none;
}
span.user-image img {
  border-radius: 50%;
  height: auto !important;
}
.header-right-left {
  width: 100%;
  margin-bottom: 0;
  margin-right: -65px;
}
}

.code-container input {
  margin-right: 16px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
}

/* range slider */
.rangeslider-horizontal {
  height: 3px !important;
  width: 445px !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #2E5CF2 !important;
}
.rangeslider-horizontal .rangeslider__handle:after {
  display: none !important;
}
.rangeslider .rangeslider__handle {
  box-shadow: none !important;
}
.rangeslider-horizontal .rangeslider__handle {
  border: 3px solid #2E5CF2 !important;
  width: 25px !important;
  height: 25px !important;
}
.rangeslider__handle-tooltip {
  display: none !important;
}








::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 4px;
	height: 0px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #2E5CF2;
}
/* header css */
.mh-header {
  padding: 10px 0;
  background-color: #fff;
}
.mh-header .header-row {
  justify-content: space-between;
}
.mh-header .mh-menu ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.mh-header .mh-menu ul li.active a:before,
.mh-header .mh-menu ul li:hover a:before {
  content: "";
  position: absolute;
  top: -15px;
  width: 100%;
  height: 5px;
  background: #b27803;
  left: 0;
}
.mh-header .mh-menu ul li.active a,
.mh-header .mh-menu ul li:hover a {
  color: #b27803;
}
.mh-header .mh-menu ul li {
  padding: 0 20px;
}
.mh-header .mh-menu ul li a {
  position: relative;
  color: #4f4f4f;
  padding: 20px 0;
  text-decoration: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.mh-header .right-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.mh-header .right-inner .icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 30px;
  margin-left: 20px;
}
.mh-header .right-inner .icon .notification {
  margin: 20px;
}
.mh-header .right-inner .icon .notification .notification-link {
  position: relative;
}
.mh-header .right-inner .icon .notification .notification-link span {
  border-radius: 50%;
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: #2e5cf2;
  right: -2px;
  top: -2px;
}
.mh-header .mh-user:after {
  content: "";
  right: 0;
  width: 13px;
  height: 8px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.mh-header .down-logout {
  cursor: pointer;
}
.mh-header .mh-user:before {
  position: absolute;
  content: "";
  left: 0;
  width: 1px;
  height: 100%;
  background: #bdc2d5;
}
.mh-header .mh-user {
  position: relative;
  padding: 0 30px;
}
b.active-user-profile {
  color: #b27803 !important;
}
.mh-header .user-link b {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 123.3%;
  color: #4f4f4f;
}
.mh-header .user-link b.on-profile {
  color: #b27803 !important;
}
.mh-header .user-link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
}
.mh-header .user-link .img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.mh-header .user-link span {
  display: block;
  color: #4f4f4f;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 123.3%;
}
.mh-header .user-link .sub-menu {
  display: none;
}

/* .mh-header .user-link .sub-menu ul */

.search-section .advance-search {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.search-section .advance-search img {
  margin: 0 20px 0 0;
}
.search-section .advance-search span {
  color: #fff;
}
.search-section {
  background-color: #2e5cf2;
  height: 234px;
  padding: 40px 0;
}
.search-section h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
}
.find-doctor {
  background: #f6f6f6;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 25px 0;
}
.find-doctor .form-inner {
  align-items: center;
  justify-content: center;
  display: flex;
}
.find-doctor .form-inner .select.box1 {
  border-right: 1px solid #bdc2d5;
  padding-right: 10px;
  position: relative;
}
.find-doctor .form-inner .select.box1 img {
  margin: 0;
}
.find-doctor .form-inner .select.box2 .autocomplete-dropdown-container {
  position: absolute !important;
  top: 43px !important;
  width: 100% !important;
  background: #fff !important;
  border-radius: 10px !important;
}
.find-doctor .form-inner .select.box1 input {
  width: 100%;
  padding: 0 10px;
}
.find-doctor .form-inner .select.box2 input {
  width: 100%;
  padding: 0 10px;
}
.find-doctor .form-inner .select.box2 img {
  margin: 0;
}


.find-doctor .form-inner .select.box1 .down-arrow {
  margin-left: auto;
  cursor: pointer;
}
.find-doctor .form-inner .select.box2 .down-arrow {
  cursor: pointer;
}

.find-doctor .form-inner .select {
  position: relative;
  width: 55%;
  margin-right: 20px;
  align-items: center;
  display: flex;
}
.find-doctor .form-inner .select select {
  width: 100%;
  border: 0;
  background-color: transparent;
}
.find-doctor .form-inner .submit-btn {
  width: 20%;
  text-align: right;
}
.find-doctor .form-inner .submit-btn input:hover {
  color: #2e5cf2;
  background: #fff;
}
.find-doctor .form-inner .submit-btn input {
  transition: all 0.5s ease;
  color: #fff;
  background: #2e5cf2;
  border: 1px solid #2e5cf2;
  border-radius: 10px;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}
.find-doctor .drop-down-list select option:hover {
  background-color: rgb(236 236 236);
}
.find-doctor .drop-down-list select option:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.find-doctor .drop-down-list select option:last-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.find-doctor .drop-down-list select option {
  padding: 5px 15px;
}
.find-doctor .drop-down-list select {
  height: 250px;
}
.find-doctor .drop-down-list {
  position: absolute;
  top: 43px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
}

/* category search  */
.searchcat-container {
  width: 100%;
  padding: 0px 20px;
}
.searchcat-container input {
  width: 100%;
}

.search-filter .inner-fliter {
  position: absolute;
  box-shadow: 0px 2px 12px rgb(0 0 0 / 25%);
  z-index: 9;
  width: 100%;
  background-color: #fff;
  left: 0;
  margin-top: 28px;
  padding: 30px;
}
.search-filter .range-fliter {
  max-width: 450px;
}
.search-filter .range-fliter .text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-filter .range-fliter .text span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #4f4f4f;
}
.search-filter .range-fliter .text p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4f4f4f;
}

.search-filter .btn-list h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4f4f4f;
}

.search-filter .btn-list ul {
  margin: 30px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.search-filter .btn-list ul li.active:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFGSURBVHgBrVLLTcNAEJ0ZO0gcIKECXAF2OiAdpIAgOZUk7oAOYkQ4kw5CCZs0gKkAFB+QYu8Ou/7LWPYB5rK7T/Pmzcw+gD8GdoHeIr5XhC5K5Zk3I3+QfRGK8DLqLeD5PGEZb5hh3qmGEKI1CpqFsCZ/O0ome2BwoL/niCw5FeHNl3lSibNMVoPkLBEcTq1NXa9UT5P3AWZQUFa5Ms7E9uot60ClZ3+IfNyO15rGJaI4X3A5glO3xIIUTRE5apLvFrFW5nWdh6457V9agI6ZCy0144Tmx5fxY5vcDCpYooFNFKk9nEeTPrLxRtUBIQkFDO0i7sNpx8x+p7KtwnyUItzF6VWX6DRQO4yhDs/Xy3oEA9pyWS+uj8wRWjKoOikvxllmcXq6px7lnc4xLowqrCvR8z+1schHxtssySKhP/5gjAP/HT8VrpEScAvl0gAAAABJRU5ErkJggg==);
  width: 16px;
  height: 16px;
}
.search-filter .btn-list ul li.active,
.search-filter .btn-list ul li:hover {
  background: #eff3fe;
  color: #2e5cf2;
}
.search-filter .btn-list ul li {
  padding: 10px 15px;
  transition: all 0.3s;
  position: relative;
  background: #f6f6f6;
  border-radius: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin: 15px 10px 0 0;
  cursor: pointer;
}

.search-filter .fliter-btn ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.search-filter .fliter-btn li {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #2e5cf2;
  border-radius: 10px;
  height: 45px;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
}
.search-filter .fliter-btn li:last-child {
  margin-right: 0;
}
.search-filter .fliter-btn li.theme-btn:hover {
  background-color: #fff;
  color: #2e5cf2;
}
.search-filter .fliter-btn li.theme-btn {
  background: #2e5cf2;
  transition: all 0.5s;
  border: 1px solid #2e5cf2;
  color: #fff;
  font-weight: normal;
}

.locationchange-modal span {
  color: black !important;
}
.form-control.locationchange-modal input {
  height: 100%;
  width: 93%;
}
.locationchange-modal .autocomplete-dropdown-container {
  margin-top: -40px !important;
}

.head-location {
  max-width: 200px;
  overflow: scroll;
  line-height: 20px !important;
  height: 20px;
}

.head-location::-webkit-scrollbar-track {
  display: none;
}

.head-location::-webkit-scrollbar {
  display: none;
}
.user-box.head-user-newblock {
  width: 240px;
}

.logout-dropdown-menu {
  position: absolute;
  width: 100%;
  background: #2e5cf2;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  margin-top: 15px;
  display: block;
  padding: 0;
  transition: 0.5s all ease-in-out;
}
.head-user-newblock .logout-dropdown-menu a {
  padding: 10px;
  border-bottom: 1px solid;
  color: #fff;
  font-weight: 600;
  transition: 0.5s all ease-in-out;
  text-decoration: none;
}
.head-user-newblock .logout-dropdown-menu ul {
  color: #fff;
  text-align: center;

}

.mh-header .hemburger {
  display: none;
}
.mh-header .mh-menu-mob {
  display: none;
}

@media(max-width: 1024px)
{
  .all-providers .details-list .provider-data li .inner-text {
    margin-left: 20px;
    width: 60%;
}
.star-div {
  width: 20%;
}
.all-providers .details-list .provider-data li .star-div .user-detailes-btn {
  padding: 5px 12px;
  width: 100%;
  text-align: center;
}
.all-providers .details-list .provider-data li .user-details {
  align-items: flex-start;
}
.all-providers .details-list .provider-data li .user-details p {
  width: 80% !important; 
}
.all-providers .details-list .provider-data li .user-details {
  flex-wrap: wrap;
}
.all-providers .details-list .provider-data li .location {
  margin-left: 39px;
  margin-top: 10px;
}
.left-inner {
  width: 15%;
}
.mh-header .right-inner {
  width: 15%;
}
.logo {
  width: 100%;
}
.mh-header .right-inner .icon {
  padding: 0 0px;
  margin-left: 0;
}
.user-box.head-user-newblock {
  width: auto;
}
.user-box.head-user-newblock .text {
  display: none;
}
.mh-header .mh-user:before
{
  display: none;
}
.mh-header .mh-user {
  position: relative;
  padding: 0 00px 0 30px;
}
}
@media(max-width: 767px)
{
  .mh-header .mh-menu ul li {
    width: 100%;
    text-align: center;
}
.find-doctor .form-inner .select.box2 img {
  padding-right: 9px;
}
.mh-header .mh-menu ul li a {
  padding: 10px 0 10px 0;
}
.mh-header .mh-menu ul li.active a:before, .mh-header .mh-menu ul li:hover a:before
{
  /* display: none; */
}
.mh-header .logo a img {
  display: inline-block !important;
  width: 65px !important;
}
.mh-header .logo {
  width: 100%;
}
.mh-header .left-inner {
  width: 30%;
}
.mh-header .right-inner {
 flex-wrap: nowrap;
}
.user-box.head-user-newblock .text {
  display: none;
}
.mh-header .right-inner {
  flex-wrap: nowrap;
  width: 70%;
  justify-content: flex-end;
}
.mh-header .right-inner .icon {
  padding: 0 10px;
  margin-left: 10px;
}
.user-box.head-user-newblock {
  width: auto;
}
.mh-header .mh-user {
  position: relative;
  padding: 0 10px;
}
.mh-header .mh-user:before{
  display: none;
}
.mh-header .down-logout {
  cursor: pointer;
}
.modal-container .modal-header {
  border-bottom: 0;
  padding-top: 10px;
}
.modal-container h1 {
  font-size: 20px;
  margin-bottom: 0px !important;
  margin-top: 14px !important;
  text-align: left !important;
  margin-right: auto;
  margin-left: 0;
}
.modal-container .modal-content {
  height: auto;
  top: 100px;
}
.rangeslider-horizontal {
  height: 3px !important;
  width: 100% !important;
}
.search-filter .inner-fliter {
  padding: 30px 0;
}
.search-filter .btn-list ul li {
  padding: 5px 10px;
}
.mh-header .mh-menu {
  display: none;
}
.mh-header .hemburger {
  display: block;
}
.mh-header .mh-menu-mob {
  display: block;
  position: absolute;
  width: 100%;
  background: #fff;
  top: 80px;
  text-align: center;
  transition: 0.3s all ease-in-out;
  z-index: 99;
}
.mh-header .hemburger {
  display: block;
  position: absolute;
  left: 100px;
  top: 31px;
  font-size: 32px;
  color: #2e5cf2;
}
.mh-menu-mob a {
  margin: 10px 0;
  font-weight: bold;
}
.mh-menu-mob li.active a {
  color: #B27803 !important;
}
.find-doctor .drop-down-list {
  z-index: 9;
}
}

.category-search-section {
  height: 100%;
}

.blogs-container{
    background: #E5E5E5;
    min-height: 100vh;
}
.blog-sec{
    background: #2E5CF2;
    height: 236px;
}
.blog-sec h2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
    text-align: center;
}
.blog-sec .find-doctor .form-inner{
    justify-content: space-between;
}
.blog-sec .find-doctor .form-inner [type="submit"]:hover{
    color: #2E5CF2;
    background-color: #fff;
}
.blog-sec .find-doctor .form-inner [type="submit"]{    
    background: #2E5CF2;
    transition: all 0.3s;
    border: 1px solid #2E5CF2;
    border-radius: 10px;
    padding: 5px;
    height: 45px;
    max-width: 180px;
    width: 100%;
    color: #fff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
}
.blog-sec .find-doctor .form-inner [type="text"]{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #4F4F4F;
}
.blogs-container .row .col-md-4 {
    cursor: pointer;
}
.blog-sec .sort-by{
    display: flex;
    align-items: center;
    color: #fff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
}
.blog-sec .sort-by span{
    font-weight: 400;
    margin: 0 15px;
    position: relative;
    cursor: pointer;
}
.blog-sec .sort-by span.active {
    margin: 0 15px;
    background: #fff;
    color: #2e5cf2;
    padding: 5px 10px;
    background: #EFF3FE;
    border: 1px solid #EFF3FE;
    box-sizing: border-box;
    border-radius: 5px;
}
.blog-sec .sort-by span:last-child:after{
    display: none;
}
.blog-sec .sort-by span:after{
    content: '';
    position: absolute;
    width: 2px;
    background: #fff;
    right: -17px;
    height: 15px;
    top: 50%;
    transform: translate(0, -50%);
}
.blog-list {
    margin-top: -25px;
}
.blog-list .box:hover{
    box-shadow: 0 5px 19px 2px rgba(0,0,0,0.1);
}


.blog-list .box{
    transition: all 0.3s;
    margin-bottom: 25px;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px;
    position: relative;
}
.blog-list .box.event:after{
    content: '';
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEEAAAAeCAYAAABzL3NnAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK7SURBVHgB7Zg/TBNRHMe/V6qhLC3BQRMboBgZNAGFmIDGDjiaOCCjJanREaMuupDKpoPRWw0k/tnUgaZu3tBEXQQtiWDswJ+0EQZN26UlWq3v9/Tq/XlHudBO7z7LXd6997vf+/7+vGuV1O22yd/AA9QQgqT4ftWQkFkAwqcAPZAcHzw8EQhPBHgicPxuJneEuh2f/dguYX97kF+r20X7i9pD/Hm5uOFoR19rnOvkw052dETrRbgSYWBiDgciUfvLCuvIvLiM0SsaVt+oWH51wzYnOrXAr9q9Ixi5qqGjs8c25/tqGu8ejSF4aACjbE5u8TG3a4TWNrKj+0RzdoMrEYifLFLvn46bxih6pa9L+MY2ER6O4Ys2Y8qGrt4odzbzPF4fo/lWsawZFB6aRKWwwe2JWE7dhD8Q5PfHz9/n10+pvzarlRJ2i3sRKkUeMRFZ5ixlQ9/pKZPj4aEYj8zmSrI+Rht2smPk6LlplDaXsLUyb3tmHOsfm+bX/OITuMW1CPsCIRw+GYPZmWR9U5QpvWf+ixDo7OYRpdQ2RprGnezo0JouVn6DE7NIqxmeFa3AvQisaZ1gvcEI1bEeVeoJ/Sx65DyN6RHKWlKaysNqR7vbZxKhzDa99mwcIyy7KMPS6jBagWsRGjWctbcqIiwTaPMfC3EcPHaBR7RsiaLeBBvBewer/cGLszh16SVaQdO/E6gccqwuKRNICMqc3Af3dWqERKTyIptOp8FeaEpPIPKGjeaZ0xHWHKkXUOaIGqCoJ1BH3/o8DxHZ1zP8lBEd0XulKT2BMIpA3ZyOS3KYnBch6gkkmJMIxAI7ms/++95oJkryVlsNkuP9doAnAscTAZ4IHE8EkAgKipAcH/u7/To7I9chMQokQ42bv4tqUO5I3RNIgGtz1YS0IugC0L2UIhgFkJKHcX/COvYHx3o+yNRO7UcAAAAASUVORK5CYII=);
    position: absolute;
    right: -5px;
    width: 65px;
    height: 30px;
    top: 20px;
    z-index: 99;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.blog-list .box .user-img{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.blog-list .box .user-img img{
    margin: 0;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}
.blog-list .box .user-img p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}
.blog-list .box .user-img span{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}
.blog-list .box .blog-img{
    margin-bottom: 20px;
}
.blog-list .box .blog-img img{
    border-radius: 8px;
    width: 100%;
    max-height: 180px;
    object-fit: cover;
}
.blog-list .box h3{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
.blog-list .box .blog-controler{
    display: flex;
    align-items: center;
    margin: 15px 0 0;
}
.blog-list .box .blog-controler .view{
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
}
.blog-list .box .blog-controler .like{
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
}
.blog-list .box .blog-controler .message{
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
}
.blog-list .box .blog-controler img{
    max-width: 20px;
    max-height: 20px;
    object-fit: contain;
    margin-right: 5px;
}
.blog-list .box .blog-controler span{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000; 
    opacity: 0.3;
}

/* Blog article */
.single-blog-head {
    background-color: #2E5CF2;
    height: 236px;
}
.single-blog-head .back-text{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.single-blog-head .back-text img{
    margin: 0;
    margin-right: 10px;
}
.single-blog-head .back-text span{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 123.3%;
    color: #fff;
}
.single-blog-head h2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 43px;
    color: #FFFF;
}
.single-blog-head .btn-list ul{
    display: flex;
    align-items: center;
}
.single-blog-head .btn-list ul li:hover{
    background-color: #2E5CF2;
    color: #fff;
}
.single-blog-head .btn-list ul li{
    transition: all 0.5s;
    background: #EFF3FE;
    border: 1px solid #EFF3FE; 
    border-radius: 5px;
    margin-right: 15px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #2E5CF2;
    cursor: pointer;
}
.single-blog-head .user-active{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: left;
}
.single-blog-head .user-active img{
    margin: 0;
    margin-right: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    cursor: pointer;
}
.single-blog-head .user-active p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    cursor: pointer;
}
.single-blog-head .user-active span{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    cursor: pointer;
}
.single-blog-head  .blog-controler{
    display: flex;
    align-items: center;
    margin: 15px 0 0;
    justify-content: flex-end;
    text-align: left;
}
.single-blog-head  .blog-controler .view{
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
    cursor: pointer;
}
.single-blog-head  .blog-controler .like{
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
    cursor: pointer;
}
.single-blog-head .blog-controler .message{
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;
    cursor: pointer;

}
.single-blog-head .blog-controler img{
    max-width: 20px;
    max-height: 20px;
    object-fit: contain;
    margin-right: 5px;
}
.single-blog-head  .blog-controler span{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #fff;  
}
.single-blog-content{
    margin-top: -20px;
}
.single-blog-content .single-img img{
    border-radius: 5px;
}   
.single-blog-content .event-active {
    background: #FFFFFF;
    border-radius: 15px;
    max-width: 650px;
    margin: 0 auto;
    margin-top: -67px;
    z-index: 999;
    position: relative;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.single-blog-content .event-active h2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #4F4F4F;
    margin-bottom: 10px;
}
.single-blog-content .event-active label{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #4F4F4F;
    display: block;
    margin: 5px 0;
}
.single-blog-content .event-active span{
    margin-left: 5px;
}
.single-blog-content .right-btn{    
    display: flex;
    align-items: center;
    width: 55%;
    justify-content: flex-end;
}
.single-blog-content .right-btn .theme-btn{
    background: #2E5CF2; 
    border-radius: 10px;
    height: 45px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    transition: all 0.5s;
    text-decoration: none;
    margin-right: 10px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFF;
    display: flex;
    align-items: center;
    padding: 0 10px;
    max-width: 150px;
    width: 100%;
    justify-content: center;
}
.single-blog-content .right-btn .theme-btn:hover{
    background-color: #E4EAFA;
    color: #2E5CF2;
}
.single-blog-content .right-btn .light-btn{
    background: #E4EAFA; 
    border-radius: 10px;
    height: 45px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    transition: all 0.5s;
    text-decoration: none;
    margin-right: 10px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #2E5CF2;
    display: flex;
    align-items: center;
    padding: 0 10px;
    max-width: 150px;
    width: 100%;
    justify-content: center;
}
.single-blog-content .right-btn .light-btn:hover{
    background-color: #2E5CF2;
    color: #fff;
}
.single-blog-content .blog-content{
    padding: 20px 0;
}
.single-blog-content .blog-content p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 154.1%;
    color: #4F4F4F;
    margin-bottom: 20px;
}
.single-blog-content .blog-content h2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 154.1%;
    margin-bottom: 15px; 
    padding-top: 20px;
    color: #4F4F4F;
}
.single-blog-content .single-bottom-btn ul{
    display: flex;
    align-items: center;
}
.single-blog-content .single-bottom-btn ul li{
    display: flex;
    align-items: center;
    margin-right: 15px; 
    cursor: pointer;
}
.single-blog-content .single-bottom-btn ul li div{
    display: flex;
    align-items: center; 
    background: #E9E7E7;
    border-radius: 10px; 
    padding: 12px 20px;
    width: 100%; 
     text-decoration: none;
}
.single-blog-content .single-bottom-btn ul li div img{
    margin: 0;
    margin-right: 10px;
}
.single-blog-content .single-bottom-btn ul li div span{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
}
.single-blog-content .container{
    border-bottom: 1px solid #c5c3c3;
}
/* .reviews_sec .reviews .reviews-inner .right form textarea{
    background-color: #fff !important;
} */

.article-container .user-active .user-text p {
    font-size: 13px !important;
    font-weight: 400 !important;
}

.article-container .user-active .user-text span {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.article-container .single-img img {
    width: 100%;
    height: 100%;
}
.article-container .single-img {
    width: 1110px;
    height: 500px;
}

  .image-preview img {
    border-radius: 5px !important;
  }
  .image-preview {
    margin-bottom: 20px;
    height: 80px;
  }
  .image-preview .preview-span {
    font-size: 11px;
    background: #2e5cf2;
    width: 20px !important;
    color: white;
    border-radius: 20px;
    height: 20px !important;
    cursor: pointer;
  }
  
  .comment-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .blog-list .container h4.no-data {
    margin-top: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #2e5cf2;
}
.sort-by-mob {
    display: none;
}

@media(max-width: 1170px)
{
    .blog-filter-row .row {
        flex-direction: column;
    }
    .blog-filter-row .row .col-md-5,.blog-filter-row .row .col-md-7  {
        max-width: 100%;
        margin-bottom: 10px;
    }
    .blog-sec .find-doctor .form-inner [type="submit"] {
        max-width: 30%;
        
    }
    .blog-sec .find-doctor .form-inner [type="text"] {
        width: 70%;
    }
    .find-doctor {
        padding: 10px 10px;
    }
}


@media(max-width: 767px)
{
    .article-container .single-img {
        width: 100%;
        height: auto;
    }
    .single-blog-head .btn-list ul {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .single-blog-head .btn-list ul li {
        margin-bottom: 10px;
    }
    .single-blog-head {
        height: auto;
    }
    .single-blog-head .user-active {
       justify-content: flex-start;
       
    }
    .single-blog-head .blog-controler {
        margin: 0;
        justify-content: flex-start;
        
    }
    .single-blog-content .event-active {
        padding: 15px;
        align-items: flex-start;
        flex-direction: column;
    }
    .single-blog-content .right-btn {
        width: 100%;
        justify-content: flex-start;
        margin-top: 10px;
    }
    .blog-sec {
        background: #2E5CF2;
        height: auto;
    }
    .blog-sec .sort-by {
        display: none;
        
    }
    .sort-by-mob {
        display: block;
    }
    .sort-by-mob label {
        color: #fff;
        min-width: 70px;
        text-transform: capitalize;
    }
    .sort-by-mob select {
        width: 83%;
        height: 30px;
        border-radius: 6px;
        background: #EFF3FE;
        color: #2e5cf2;
    }
    .blog-sec .find-doctor .form-inner [type="text"] {
        width: 100%;
        padding-left: 10px;
        height: 30px;
        border-bottom: 1px solid #999;
        padding-bottom: 10px;
        margin-bottom: 15px;
    }
    .blog-sec .find-doctor .form-inner [type="submit"] {
        max-width: 96%;
        height: 33px;
    }
    .blog-sec .find-doctor .form-inner {
        justify-content: space-between;
        flex-direction: column;
    }
    
}

@media(max-width: 480px)
{
    .single-blog-head h2 {
        font-size: 20px;
        line-height: 28px;
    }
    .single-blog-content .right-btn {
        justify-content: flex-start;
        flex-direction: column;
    }
    .single-blog-content .right-btn .theme-btn {
        max-width: 100%;
        margin-bottom: 10px;
    }
    .single-blog-content .right-btn .light-btn {
        max-width: 100%;
        
    }
    .sort-by-mob select {
        width: 77%;
        height: 30px;
    }

}
/* comman css */
.popular-categories1 {
    background-color: #e5e5e5;
    min-height: 100vh;
  }
  .row {
    display: flex;
    /* -ms-flex-wrap: wrap;
    flex-wrap: wrap; */
    margin-right: -15px;
    margin-left: -15px;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .justify-content-center {
    justify-content: center;
  }
  .align-items-center {
    align-items: center;
  }
  
.popular-categories1 {
    padding: 0;
}

.logo-list{
    width: 100%;
}
.logo-list ul {
  display: flex;
  -ms-flex-wrap: wrap;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
 
.logo-list ul li {
  margin: 10px 10px;
}
.logo-list ul li a img {
  padding: 5px 0;
  width: 50px;
}
.logo-list ul li a span {
  padding-bottom: 5px;
  font-weight: 500;
  margin-top: 10px;
  font-size: 20px;
}
.all-categories .logo-list ul li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #ffffff;
  border-radius: 15px;
  text-decoration: none;
  color: #4f4f4f;
  font-size: 15px;
  line-height: 18px;
  width: 255px;
  height: 200px;
}
.logo-list ul li a:hover {
  box-shadow: 0 5px 19px 2px rgb(0 0 0 / 10%);
  transition: 200ms;
}
.logo-list ul li a p{
    font-size: 13px;
    font-weight: 400;
    
}

.all-categories {
  margin-top: -40px !important;
  max-width: 1110px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 140px;
}
.all-categories .row {
  display: flex;
  /* flex-wrap: wrap; */
  margin: 0 auto;
}

.all-categories .row .logo-list h1 {
  color: #ffffff;
}

.find-doctor .form-inner .submit-btn {
  width: 170px;
  text-align: right;
}

@media(max-width: 480px)
{
  .find-doctor .form-inner {
    flex-direction: column;
}
.find-doctor .form-inner .submit-btn {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
.searchcat-container {
  width: 100%;
  padding: 0 0 10px 10px;
  border-bottom: 1px solid #ccc;
}
.find-doctor {
  padding: 10px 0px;
 
}
.submit-btn input[type="submit"] {
  width: 90%;
}
.search-section {
  height: auto;
  padding: 20px 0 10px;
}
.all-categories {
  margin-top: -25px !important;
  
}
.logo-list ul li a {
  width: 100%;
  height: auto;
  padding: 15px;
}
.logo-list ul li a span {
  margin: 15px 0;
  padding: 0;
}
}
/* comman-blue-header */

.doctor-container {
  background-color: #f8f9fb;
  height: 100%;
  padding-bottom: 100px;
}

.blue-block {
  width: 100%;
  height: 234px;
  background: #2e5cf2;
}

.star-div .img {
  display: flex;
  -ms-flex-wrap: wrap;
  align-items: center;
}
.star-div .img img {
  margin-right: 4px;
}
.star-div span {
  font-family: Montserrat;
  margin-left: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.doctor-detailes {
  margin: 0 auto;
  max-width: 1110px;
  width: 100%;
  font-family: Montserrat;
  margin-top: -200px;
}

.detailes-top {
  display: flex;
}
.detailes-top .profile-image {
  width: 350px;
  height: 400px;
}
.right-detailes {
  width: 100%;
}

.basic-info .name {
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
}

.top-data {
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
}

.loc-rating .location {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.loc-rating .location .loc {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: flex-end;
}
.location span {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  padding-left: 5px;
}
.specialties {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}
.specialties span {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: white;
  border-radius: 7px;
  padding: 2px 10px;
  margin-top: 5px;
  margin-right: 8px;
  color: #2e5cf2;
  font-size: 13px;
  font-weight: 400;
}

.contact-info {
  margin-top: 98px;
  margin-left: 30px;
  display: flex;
  justify-content: space-between;
}
h3.contact-title {
  font-size: 18px;
  font-weight: 500;
}

.teleHealth {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
  font-size: 10px;
  color: #2e5cf2;
}

.info-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: start;
  font-size: 14px;
  font-weight: 400;
}
.info-field div {
  margin-right: 10px;
}

.back-shade {
    background: #e3e9fa;
    padding: 7px;
    border-radius: 5px;
}
.contact-data .group-data {
  margin-top: 12px;
}

.profile-image img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 5px;
}

/* schedule data */

.top-arrow {
  display: flex;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
  cursor: pointer;
}
.top-arrow span {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.schedule {
  display: flex;
  align-items: end;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 20px;
}
.timings-top {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  justify-content: space-around;
  margin-top: 5px;
}
.timings-top h3 {
  padding: 0px 10px;
}
.open-text {
  color: #b37803;
}
.days {
  width: 100%;
}
.day {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: calc(50% - 16px / 2 - 39.5px);
  font-size: 13px;
  font-weight: 400;
}

.days .day span.time {
  padding-left: 22px;
  width: 135px;
}

.days .active {
  font-weight: 500;
}

/* detailes bottom */

.detailes-bottom {
  margin-top: 35px;
}
.detailes-bottom .tab-view {
  background: #ffffff;
  padding: 20px 32px;
  border-radius: 0px 15px 15px 15px;
  padding-bottom: 100px;
}
.tabs-container {
  width: 315px;
  height: 40px;
  background: #eff3fe;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs-container span {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  border-radius: 5px 0px 0px 0px;
}

span.graduation,
.edu-desc {
  font-size: 15px;
  font-weight: 400;
}
h3.edu-title {
  font-size: 18px;
  font-weight: 500;
}
.experience ul li {
  font-size: 15px;
  font-weight: 400;
  list-style: outside;
}
.experience ul {
  padding-left: 18px;
}
.education,
.courses,
.experience {
  margin-top: 26px;
}
.courses {
  display: flex;
  flex-direction: column;
}
.tabs-container span.active {
  background: #ffffff !important;
  color: #2e5cf2;
  font-weight: 500;
}
.tabs-container span:last-child.active {
  border-top-right-radius: 5px;
  border-top-left-radius: 0px;
}
.reviews ul.rating {
  display: flex;
  align-items: center;
}
.reviews ul.rating li {
  margin: 0 60px 0 0;
}
.reviews .reviews-inner {
  display: flex;
  justify-content: space-between;
}
.reviews .reviews-inner .left {
  width: 60%;
  padding-top: 25px;
}
.article-container .reviews .reviews-inner .left.full{
  width: 100%;
  padding-top: 25px;
}

.reviews .reviews-inner .left .star img {
  margin: 0;
  width: 11.5px !important;
  height: 11px !important;
}
.reviews .reviews-inner .right {
  width: 30%;
  padding: 20px;
}
.reviews .reviews-inner p {
  margin-top: 5px;
}
.reviews .reviews-inner ul.rating {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
}

.reviews ul.reviews-content {
  padding: 20px 0;
}
.reviews ul.reviews-content li {
  padding: 15px 0;
}
.reviews ul.reviews-content li .content-inner {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}

.reviews ul.reviews-content li .img img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin-right: 10px;
}
.reviews ul.reviews-content li .text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.reviews ul.reviews-content li .text .star {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.reviews ul.reviews-content li .right-date {
  margin-left: auto;
}
.reviews ul.reviews-content li .right-date span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}
.reviews ul.reviews-content li .bottom .like {
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
.reviews ul.reviews-content li .bottom .like img {
  margin: 0 5px 0 0;
}
.reviews ul.reviews-content li .bottom .like.active {
  color: #b37803;
}
.reviews ul.reviews-content li .bottom .content-img img {
  max-width: 200px;
  max-height: 150px;
  border-radius: 20px;
  object-fit: cover;
}
.reviews ul.reviews-content li .bottom p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #4f4f4f;
  margin-bottom: 15px;
}
.reviews img {
  margin: 0;
}

.reviews .reviews-inner .right h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}
.reviews .reviews-inner .right .star-inner {
  display: flex;
  align-items: center;
  padding: 10px 0 20px;
  border-bottom: 1px solid #dadada;
}
.reviews .reviews-inner .right .star-inner img {
  margin-right: 28px;
  width: 26.14px;
  height: 25px;
}

.reviews .reviews-inner .right form {
  padding: 15px 0;
}
.reviews .reviews-inner .right form .form-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 10px 0 15px;
}
.reviews .reviews-inner .right form textarea,
.reviews .reviews-inner .right form input {
  background: #f6f6f6;
  border-radius: 10px;
  border: 0;
  padding: 10px;
  font-size: 15px;
  color: #4f4f4f;
  resize: none;
}
.reviews .reviews-inner .right form textarea {
  padding: 15px;
}
.reviews .reviews-inner .right form .no-comment {
  border: 2px solid rgb(247, 145, 145);
}
.reviews .reviews-inner .right .file {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-top: 15px;
  width: 100%;
}

.reviews .reviews-inner .right .btn {
  color: gray;
  background: #f6f6f6 !important;
  width: 100%;
  font-weight: normal;
  font-size: 15px !important;
  line-height: 18px !important;
  padding-left: 40px;
  position: relative;
}
.reviews .reviews-inner .right .btn:before {
  content: "";
  position: absolute;
  top: 50%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAWCAYAAAAxSueLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGJSURBVHgBrVYtU8NAFNwyjaCiiAhghgoqQASBAAE/HwGipgZTUURMTZgpIhU13MJec+0k9w6anXlzN7mbt+/7MkAaRsb5VhLFADbunUwS7i2dvMcuDBHHhYioaB25d+lk6mTlpLLIMl2+OjjPtJZOvtCNTxn2iP1wbkW+4N6TFfKAB3WLsg3ioNJlYJwHcz3VOhvqwkTWz/E/kKwrX4UIM09GVIjDG5VrT4KVjAxBL560f0ET/swqEI8zJw9SxDBv9I15unHyhib8OZpWGYdKUshGImKbvGI/AvS0kCf0gt6WIvHFsevRE9ig5VkLEaR4hqYQPJi/xaGiFDJaz9zUHeeVJIcBi2wUKIyBRTDGkWS9wiJj6JhoK0TniE+YJDKCk8H3VxtYQAx3aSlKIfvAb19x7v1MAn3neufkVgaZZCl9xjCy7OlBIQlhPi1/ISOYO85NehmOqxIJj2YbmfUaE2vE37U2ZCEZrWYfXaN/UD+Lizmv/W8BvXp2cor+QUfmIRkC0r6xG3PfDPNUE3hwgyYAAAAASUVORK5CYII=);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  width: 20px;
  height: 20px;
  transform: translate(10px, -50%);
}
.reviews .reviews-inner .right input[type="file"] {
  width: 100%;
  max-width: 100%;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.reviews .reviews-inner .right input[type="submit"]:hover {
  background-color: #fff;
  color: #2e5cf2;
}
.reviews .reviews-inner .right input[type="submit"] {
  display: block;
  transition: all 0.3s;
  margin: 15px 0;
  width: 100%;
  background: #2e5cf2;
  border: 1px solid #2e5cf2;
  border-radius: 10px;
  padding: 10px 20px;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

.reviews .reviews-inner .right textarea::-webkit-input-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.reviews .reviews-inner .right textarea:-ms-input-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.reviews .reviews-inner .right textarea::-ms-input-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}

.reviews .reviews-inner .right textarea::placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
}
.reviews-inner .rating span {
  font-size: 18px;
  font-weight: 500;
}
.reviews-inner .rating p {
  font-size: 13px;
  font-weight: 400;
}

.loc-rating .star-div .img span {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 0px !important;
}


.loc-rating .star-div .img img {
  width: 14px !important;
  height: 14px !important;
}
.loc-rating .star-div .img .rev-count {
  padding-left: 10px;
}
ul.loader.doctor-loader {
  display: flex;
  margin-top: 30px;
}
li.review-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.doctor-container .image-preview img {
  border-radius: 5px !important;
}
.doctor-container .image-preview {
  margin-bottom: 20px;
  height: 80px;
}
.doctor-container .image-preview span {
  font-size: 11px;
  background: #2e5cf2;
  width: 20px !important;
  color: white;
  border-radius: 20px;
  height: 20px !important;
  cursor: pointer;
}

.comment-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.contact-data.website a{
  text-decoration: none;
}



@media(max-width: 1024px)
{
  .doctor-detailes {
    padding: 15px;
}
.star-div {
  width: 100%;
}
.back-shade {
   width: 30px;
}
.timings-top h3 {
  padding: 0px 10px;
  font-size: 14px;
}
.reviews .reviews-inner .right .star-inner img {
  margin-right: 6px;
  
}
.reviews ul.rating li {
  margin: 0 20px 0 0;
}


}

@media(max-width: 991px)
{
  .reviews .reviews-inner .right {
    width: 30%;
    padding: 20px 0;
}
.reviews .reviews-inner .right form textarea {
 width: 100%;
}
}
@media(max-width: 767px)
{
  .reviews .reviews-inner {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.reviews .reviews-inner .left {
  width: 100%;
  padding-top: 25px;
}
.reviews .reviews-inner .right {
  width: 100%;
  padding: 20px 0;
  order: -1;
}
.reviews .reviews-inner .right form textarea {
  padding: 15px;
  width: 100%;
}
.detailes-top {
  display: flex;
  flex-direction: column;
  position: relative;
}
.detailes-top .profile-image {
  width: 180px;
  height: 200px;
}
.contact-info {
  margin-top: 20px;
  margin-left: 0;
  flex-direction: column;
}
.doctor-detailes {
  
  margin-top: -240px;
}
.top-data {
  margin-left: 0;
  position: absolute;
  top: 0;
  flex-direction: column;
  left: 200px;
}
.loc-rating .location {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
}
.specialties {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
}

@media(max-width: 480px)
{
  .reviews ul.rating li {
    margin: 0 6px 0;
    width: calc(100% / 3 - 10px);
}
.reviews ul.rating li p {
  word-break: break-all;
}
.reviews .reviews-inner ul.rating {
    align-items: flex-start;
}
.detailes-bottom .tab-view {
  padding: 20px 10px;
  
}
.reviews-inner .rating span {
  font-size: 24px;
  font-weight: 500;
}
.reviews-inner .rating p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
}
}
.profile-container, .profile-list-inner{
    background: #E5E5E5;
}
.profile-container .header-data {
    width: 100%;
    background: #2E5CF2;
    color: #ffffff;
    display: flex;
    height: 230px;
    align-items: center;
}

.profile-container .mid-data .back-shade {
    background: #fff;
    padding: 7px;
    border-radius: 5px;
}
.profile-container .mid-data .email{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.profile-container .mid-data .email .back-shade{
    margin-right: 10px;
}
.profile-container .mid-data .change-mail{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
    margin-left: 40px;
    cursor: pointer;
}
.profile-container .mid-data .email p{
    color: #fff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
}
.profile-container .mid-data {
    display: flex;
    align-items: center;
    max-width: 1110px; 
    width: 100%;
    margin: 0 auto;
}
.profile-container .right-data .password{
    margin-top: 20px;;
}
.profile-container .right-data .location span{
    margin-top: 0;
    padding-left: 10px;
}
.profile-container .right-data .password span{
    padding-left: 10px;
}
.profile-container .right-data .change-location{
    padding-left: 40px;
    cursor: pointer;
}
.profile-container .profile-right .name-container, 
.profile-container .right-data .address .location, 
.profile-container .right-data .password {
    display: flex;
    align-items: center;
}
.profile-container .profile-right{
    width: auto;
}
.profile-container .right-data{
    margin-left: auto;
}
.profile-container .right-data .password span{
   cursor: pointer;
}
.profile-container .left-data {
    width: 217px;
    justify-content: space-between;
}
.profile-container .profile {
   position: relative;
}
.profile-container .profile img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    max-width: 150px;
    max-height: 150px;
    object-fit: cover;
    background-color: white;
}
.profile-container .left-data .profile-right {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 30px;
}
.profile-container .name-container img {
    cursor: pointer !important;
}
.profile-container .profile-right span { 
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    margin-right: 20px;
}

.profile-container .change-profile{
    bottom: 0;
    position: absolute;
    overflow: hidden;
    display: inline-block;
    right: 0;
    cursor: pointer !important;
}
.profile-container .change-profile .btn{
    border: 5px solid #FFF;
    color: #2D5BF0;
    background-color: #EAEFFE !important;
    padding: 10px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 500;
    font-size: 35px !important;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
}

.profile-container .change-profile input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer !important;
  }

.profile-list-inner{
    padding: 50px 0;
}
.profile-list-inner .tabs-container span.active{
    border-top-left-radius: 5px;
}
.profile-list-inner .tabs-container span:last-child.active{
    border-top-right-radius: 5px;
    border-top-left-radius: 0px;
}
.profile-list-inner .tabs-container{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.profile-list-inner .tab-view ul li:hover{
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}
.profile-list-inner .tab-view{ 
    background-color: #fff;
    min-height: 700px;
}
.profile-list-inner .tab-view ul{ 
    background-color: #fff;
    min-height: 700px;
}
.profile-list-inner .tab-view ul li{ 
    background-color: #fff;
    padding: 15px 20px;
}
.profile-list-inner .tab-view ul li .list-inner{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.profile-list-inner .tab-view ul li .text h5{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    margin-top: 15px;
    color: #2E5CF2;
}
.profile-list-inner .tab-view ul li .text p{   
    background: #EFF3FE;
    border: 1px solid #EFF3FE;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
}
.profile-list-inner .tab-view ul li .img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.profile-list-inner .tab-view ul li .img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.profile-list-inner .tab-view ul li .list-icon{
    margin-left: auto;
    display: flex;
    align-items: center;
}
.profile-list-inner .tab-view ul li .like img{
    margin-right: 10px;
}
.profile-list-inner .tab-view ul li .like{
    display: flex;
    align-items: center;
    margin-right: 25px;
}
.profile-list-inner .tab-view ul li .star{
    display: flex;
    align-items: center;
    margin-right: 25px;
}
.profile-list-inner .tab-view ul li .date p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #4F4F4F;
}
.profile-list-inner .tab-view.notification{
    background-color: #fff;
    width: 100%;
    max-width: 100%;
    min-height: 700px;
}
.profile-list-inner .tab-view.notification ul{
    padding: 20px 30px;
}
.profile-list-inner .tab-view.notification ul li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bfb7b736;
    padding: 20px 15px;
}
.profile-list-inner .tab-view.notification ul li input{
    margin-right: 15px;
}
.profile-list-inner .tab-view.notification ul li span{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
}
.tabs-container li p {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 172.96%;
    color: #4f4f4f;
}

.tab-view {
    min-height: 700px !important;
    padding-bottom: 100px;
}


/* popup Modal  */
.modal-container .modal-header{border-bottom: 0; padding-top: 80px;}
.modal-container h1{
    text-align: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    margin-bottom: 30px !important;
    line-height: 123.3%;
    color: #2E5CF2;
    margin-top: 50px;
    text-transform: uppercase;
    margin: 0 auto;

}
.modal-container .form-control {
    position: relative;
    height: 45px; 
    border: 0;
    margin-bottom: 15px;
    padding-left: 18px;
background: #F6F6F6;
border-radius: 10px;
}

.modal-container .form-control input:focus ~ .floating-label,
.modal-container .form-control input:not(:focus):valid ~ .floating-label{
  top: 15px;
  bottom: 5px;
  left: 15px;
  font-size: 11px;
  opacity: 1;
  font-size: 11px;
  line-height: 13px;
  color: #000;
  opacity: 0.4;
}
.modal-container .modal-header button:focus{
    outline: none;
   
}
.modal-container .modal-header button{
    position: absolute;
    right: 15px;
    top: 15px;
}
.modal-container .modal-header span{
    color: #B1BAD3;
    font-size: 40px;
    font-weight: 400;
}
.modal-container .form-control .inputText {
    font-size: 15px;
    width: 200px;
    font-weight: 400;
    height: 35px;
    margin-top: 5px;
}
.modal-container .form-control .floating-label {
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 50%;
  font-size: 15px;
  transition: 0.2s ease all;
  transform: translate(0, -50%);
  color: #000;
}

.modal-container .form-control input{ 
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
}
.modal-container [type="submit"]:hover{
    color: #2E5CF2;
    background-color: #fff;
}
.modal-container [type="password"]{font-size: 35px;}
.modal-container [type="submit"] {
    margin-top: 30px;
    height: 55px;
    background: #2E5CF2;
    border: 1px solid #2E5CF2;
    transition: all 0.4s ;
    border-radius: 5px;
    width: 100%;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}
.modal-container .modal-dialog{
    max-width: 600px !important;
}
.modal-container .modal-content{
    border: 1px solid #EFF2F6;
    box-sizing: border-box;
    box-shadow: 0px 24px 24px rgb(0 0 0 / 15%);
    border-radius: 8px;
    position: relative;
    height: auto;
    top: 100px;
}

.modal-container .modal-body{
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 60px;
    width: 100%;
}
.modal-container .modal-body .form-control img {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
}
.modal-container .modal-body .location .form-control img{
    top: 19px;
    cursor: pointer;
}
 
.modal-container .modal-body .location ul.inner-list li{
    cursor: pointer;
    padding: 10px 8px;
}
.modal-container .modal-body .location ul.inner-list li:hover {
    background: #f6f6f6;
}
.modal-container .modal-body .location ul.inner-list{
    position: absolute;
    max-height: 300px;
    overflow: scroll;
    transition: all 0.3s;
    background: #fff;
    width: 100%;
    right: 0;
    padding: 10px;
    border-radius: 8px;
    display: none;
}
.modal-container .modal-body .location ul.show-loc {
    display: block;
}

.profile-list-inner .list-inner .list-icon .star span {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.profile-list-inner .list-inner .list-icon .star span {
    margin-right: 1px;
  }
.profile-list-inner .list-inner .list-icon .star img {
    width: 11.5px !important;
    height: 11px !important;
}

.user-comments-loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px !important
}

.user-comments-list h4.no-data {
    padding-top: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: #2e5cf2;
}

.profile-container .mid-data {
    padding: 0 15px;
}

@media(max-width: 767px)
{
    .profile-container .header-data {
        width: 100%;
        height: auto;
        padding: 15px;
    }
    .profile-container .mid-data {
        flex-direction: column;
        position: relative;
    }
    .profile-right {
        width: 100% !important;
        padding-left: 0 !important;
        text-align: center;
        margin: 15px 0;
    }
    .profile-container .right-data {
        margin-left: 0;
        width: 100%;
    }
    .profile-container .mid-data .change-mail {
        margin-left: 0;
        width: 100%;
        display: block;
        text-align: left;
        padding-left: 40px;
    }
    .profile-container .right-data .change-location {
        font-size: 13px;
        font-weight: 600;
        
    }
    .profile-container .profile img {
        margin-right: auto;
        margin-left: 0;
    }
    .profile-container .profile {
        position: relative;
        width: 100%;
    }
    .profile-container .change-profile {
        left: 100px;
    }
    .name-container {
        position: absolute;
        top: 30px;
        left: 170px;
    }
}

@media(max-width: 480px)
{
    .profile-container .profile img {
        width: 100px;
        height: 100px;
        
    }
    .name-container {
        left: 130px;
    }
    .profile-container .change-profile {
        left: 60px;
        bottom: -11px;
    }
    .profile-list-inner .tab-view ul li .img {
        width: 20%;
        height: auto;
        margin-right: 10px;
    }
    .profile-list-inner .tab-view ul li .list-inner {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        flex-wrap: wrap;
        position: relative;
    }
    .profile-list-inner .tab-view ul li .list-icon {
        margin-left: auto;
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 70px;
    }
    .list-icon .date {
        position: absolute;
        right: 0;
        top: 16px;
    }
    .profile-list-inner .tab-view ul li .text h5 {
        margin-top: 0;
        
    }
}


.switch-container {
    display: flex;
    align-items: center;
    margin-right: 14px;
}
input[type="checkbox"].switch  {
	position: absolute;
	opacity: 0;
}

input[type="checkbox"].switch + div {
	vertical-align: middle;
	width: 44px;
	height: 23px;
	border-radius: 999px;
	background-color: #EFF3FE;
	transition-duration: .4s;
	transition-property: background-color, box-shadow;
	cursor: pointer;
}

input[type="checkbox"].switch + div span {
	position: absolute;
    font-size: 1.6rem;
    color: white;
    margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child( 1 ) {
    margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child( 2 ) {
    margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
	width: 44px;
	background-position: 0 0;
	background-color: #2E5CF2;
}

input[type="checkbox"].switch + div {
    width: 44px;
    height: 23px;
}


input[type="checkbox"].switch:checked + div {
	background-color: #2E5CF2;
    ;
}

input[type="checkbox"].switch + div > div {
    float: left;
    width: 21px;
    height: 21px;
    border-radius: inherit;
    background: #ffffff;
    transition-timing-function: cubic-bezier(1,0,0,1);
    transition-duration: 0.4s;
    transition-property: transform, background-color;
    pointer-events: none;
    margin-top: 1px;
    margin-left: 1px;
}


input[type="checkbox"].switch:checked + div > div {
	transform: translate3d(10px, 0, 0);
	background-color: #ffffff;
}

input[type="checkbox"].bigswitch.switch + div > div {
	width: 44px;
	height: 23px;
	margin-top: 1px;
}


input[type="checkbox"].switch:checked + div > div {
	transform: translate3d(21px, 0, 0);
}

