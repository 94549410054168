body {
  font-family: "Montserrat", sans-serif;
  background: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
}
/* CSS Document */
* {
  padding: 0;
  margin: 0;
  list-style: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}
/* remember to define focus styles! */
:focus {
  outline: 0;
}
ol,
ul {
  list-style: none;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
blockquote,
q {
  quotes: "" "";
}

input {
  background: none;
  border: 0 none;
  outline: none;
}
table {
  border: 0 none;
  border-collapse: collapse;
  border-spacing: 0;
}
td {
  vertical-align: top;
}
img {
  border: 0 none;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  display: block;
  margin: 0 auto;
}
a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
  display: block;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
/*responsive menu*/
.menu {
  color: #333333;
  cursor: pointer;
  float: left;
  font-family: "Lato", sans-serif;
  font-size: 22px;
  line-height: 31px;
  display: none;
}
.menu .menu-trigger {
  float: left;
  margin: 8px 20px 0 25px;
}
.menu .line:nth-child(1):after {
  transition: all 0.6s linear 0.1s;
}
.menu .line:after {
  background: #ffffff;
  content: "";
  display: block;
  height: 3px;
  width: 0;
}
.menu .line {
  background: #333333;
  display: block;
  height: 3px;
  margin-bottom: 5px;
  width: 33px;
}
.header-sec .menu-wrap .menu:hover .line:after {
  transform: rotate(360deg);
  width: 100%;
}
.show-menu .side-menu {
  transition: all 1.4s ease 0s;
  z-index: 1;
}
.side-menu {
  display: none;
  background: #333333;
  height: 100%;
  overflow: hidden;
  padding: 69px 50px 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.4s ease 0s;
  width: 225px;
  z-index: -1;
}
.show-menu #wrapper {
  transform: translateX(-325px);
}
#wrapper {
  background: #ffffff;
  position: relative;
  transition: all 0.3s ease 0s;
  /* z-index: 99999; */
}
/*responsive menu end */
.container {
  margin: 0 auto;
  max-width: 1110px;
  width: 100%;
}
.btn {
  background: #b27803 !important;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: center;
  color: #ffffff;
  border: 2px solid transparent;
  font-family: "Montserrat", sans-serif;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  padding: 8px 20px;
}
.autocomplete-dropdown-container {
  position: absolute;
  top: 90px;
  width: 98%;
}
.autocomplete-dropdown-container .fa,
.autocomplete-dropdown-container span {
  padding: 2px 5px;
}

.btn:hover {
  background: none !important;
  color: #b27803;
  border: 2px solid #b27803;
}

.btn-primary {
  background: #78b06e;
}
.btn-secondary {
  background: #335480;
}
.title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 123.3%;
  color: #b27803;
}

p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 172.96%;
  color: #4f4f4f;
}
/**************** Header ****************/
.inner-header .login-header-menu {
  padding: 0 50px;
  margin-top: 10px;
}
.inner-header .login-header-menu a {
  text-decoration: none;
}
.login {
  background: url(../images/nurse.png) no-repeat !important;
  background-position: top right !important;
  height: 100vh;
}
.user-login {
  background: url(../images/patienttest.svg) no-repeat !important;
  background-position: top right !important;
  height: 100vh;
}
.forgot-login {
  background: url(../images/forgetlogin.svg) no-repeat !important;
  background-position: top right !important;
  height: 100vh;
}

.title-block {
  margin-bottom: 10px !important;
}
#header {
  padding-top: 30px;
}
.logo a {
  /* width: 20%; */
  display: block;
  text-align: left;
}
.logo a img {
  display: inline-block;
}
.login-left {
  margin-top: 70px;
}
.login-left .title {
  margin-bottom: 45px;
}
.form-field-wrapper label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
  display: block;
  margin-bottom: 13px;
}
.form-field-wrapper .txt {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 330px;
  height: 50px;
  width: 100%;
  padding-left: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #232ed1;
}
.form-field.full {
  margin-bottom: 30px;
}

.form-field-wrapper .txt:focus {
  background: #ffffff;
  border: 1px solid #2e5cf2;
  box-sizing: border-box;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.btn-wrapper .btn {
  max-width: 330px;
  height: 55px;
  width: 100%;
  margin-top: 10px;
}
.notlogin {
  margin-top: 40px;
  max-width: 330px;
  width: 100%;
  text-align: end;
}
.notlogin p {
  display: flex;
  justify-content: center;
}
.notlogin p a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #2e5cf2;
  margin: 0 17px;
  transition: 0.3s all ease-in-out;
  position: relative;
}
.notlogin p a:hover {
  color: #b27803;
}
.notlogin p a:first-child:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 2px;
  background: #2e5cf2;
  right: -17px;
  top: 8px;
  border-radius: 50%;
}
.txt::-webkit-input-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #bfbfbf;
}
.txt::-moz-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #bfbfbf;
}
.txt:-ms-input-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #bfbfbf;
}
.txt:-moz-placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #bfbfbf;
}
.pass {
  background: transparent url(../images/eye.png) no-repeat !important;
  background-position: right 10px center !important;
}

/*****Privacy****/
.privacy{
  background-position: top right !important;
  min-height: 100vh;
  padding-bottom: 63px;
  font-weight: bold;
  line-height: 10;
}

.contactUs{
  border: 5px solid blueviolet;
  padding: 50px;
}

/********* Register *********/


.register, .user-register {
  background: url(../images/picture.jpg) no-repeat !important;
  background-position: top right !important;
  min-height: 100vh;
  padding-bottom: 63px;
}
.user-register {
  background: url(../images/registerimg.svg) no-repeat !important;
  background-position: top right !important;
  min-height: 100vh;
  padding-bottom: 63px;
}

.refer-user{
  background: url(../images/picture.jpg) no-repeat !important;
  background-position: top right !important;
  min-height: 100vh;
  padding-bottom: 63px;
}

.register .login-left .title {
  margin-bottom: 10px;
}
.login-link {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 173.3%;
  color: #4f4f4f;
}
.login-link a {
  color: #2e5cf2;
  display: inline-block;
  transition: 0.3s all ease-in-out;
}
.login-link a:hover {
  color: #b27803;
}
.register-form {
  margin-top: 50px;
}
.register .form-field-wrapper {
  max-width: 710px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;
}
.form-field.uploadfile {
  max-width: 350px;
  border: 1px dashed #dedede;
  width: 100%;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.register .login-left {
  margin-top: 0px;
}
.form-field.uploadfile p {
  display: block;
  width: 100%;
  position: absolute;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-field.uploadfile p span:first-child {
  margin-right: 10px;
}
.form-field.uploadfile p span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
}
input.fu {
  position: relative;
  opacity: 0;
  height: 88px;
  width: 100%;
  cursor: pointer;
}
.uploaded-image {
  position: absolute;
  height: 88px;
  width: 88px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
}
.form-field-wrapper .form-field {
  width: 47%;
}
.form-field select {
  height: 55px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #232ed1;
  padding-left: 10px;
  appearance: none;
  background: url("../images/select-arrow.png") no-repeat right 21px center;
  background-color: #fff;
}
.form-field select option {
  color: #4f4f4f;
}
.form-field select:focus {
  border: 1px solid #2e5cf2;
}
.form-field.form-title select {
  width: 160px;
}
.form-field.uploadfile {
  width: 49%;
}
.modal-90w {
  width: 980px;
  max-width: none !important;
}

.inner-header.main-header {
  display: flex;
  justify-content: space-between;
}
.main-header .logo {
  width: 40%;
}
.main-header .logo a img {
  width: 75px;
}
.header-right {
  display: flex;
  width: 60%;
  justify-content: flex-start;
}
.header-right-left ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-right-left ul li {
  margin-right: 26px;
}
.btn-secondary {
  border: 2px solid #2e5cf2;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  padding: 0px 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #2e5cf2;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease-in-out;
}
.btn-secondary p {
  margin-right: 14px;
}
a.btn-secondary:hover {
  background: #2e5cf2;
  color: #fff;
}
a.btn-secondary:hover img {
  filter: brightness(0) invert(1);
}
.current-user {
  display: flex;
  align-items: center;
}
.header-right-right {
  width: 48%;
}
.current-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dropdown-menu-arrow {
  margin-left: 65px;
}
.user-image {
  margin-right: 15px;
  border-radius: 50%;
}
span.user-image img {
  border-radius: 50%;
}
.username h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 123.3%;
}
.current-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-left: 1px solid #bdc2d5;
}
.header-right-left {
  width: 50%;
}
.dropdown-menu {
  width: 100%;
  background: #b27803;
  margin-top: 15px;
  display: none;
  padding: 0;
}
.dropdown-menu a {
  padding: 10px;
  border-bottom: 1px solid;
  color: #fff;
  font-weight: 600;
  transition: 0.5s all ease-in-out;
}
.dropdown-menu ul {
  color: #fff;
  text-align: center;
}
.dropdown-menu a:hover {
  background: #444;
  text-decoration: none;
}
.dashboard .inner-header.main-header {
  padding-bottom: 35px;
}
.filter-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-left {
  width: 40%;
}
.filter-right {
  width: 60%;
}
.filter-right-inner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.view button {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;
  height: 45px;
  width: 45px;
  margin-right: -5px;
}
.view {
  margin-right: 5px;
  margin-left: 20px;
}
.sel {
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  height: 45px;
  width: 155px;
  margin-left: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
  padding-left: 5px;
}
.search-form {
  display: flex;
  align-items: center;
}
.btn-search {
  border: none;
  background: none;
  order: -1;
  width: 24px;
  height: 24px;
  margin-right: 13px;
}
.txtsearch {
  width: 75px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 123.3%;
  color: #4f4f4f;
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid transparent;
  height: 45px;
}
.txtsearch:focus {
  width: 150px;
  border-bottom: 1px solid #dedede;
}
.filter-table {
  margin-top: 30px;
  margin-bottom: 5%;
}
.filter-table ul {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 6px 0;
  margin-bottom: 10px;
  align-items: center;
  border: 1px solid transparent;
  transition: 0.2s all ease-in-out;
}
.filter-table ul:hover {
  border: 1px solid #2e5cf2;
  box-sizing: border-box;
  box-shadow: 0px 11px 22px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  position: relative;
  z-index: 999;
}
.filter-table ul li {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 172.96%;
  color: #4f4f4f;
  width: 12.5%;
  word-break: break-all;
}
.filter-table ul li:first-child {
  width: 5%;
}
.filter-table ul li:nth-child(2) {
  width: 12.5%;
}
.filter-table ul li:last-child {
  width: 2%;
}
.filter-table ul li:nth-child(6),
.filter-table ul li:nth-child(7) {
  width: 18%;
}
.filter-title li {
  color: #b27803 !important;
}
.filter-table ul li img {
  border-radius: 50%;
  margin-left: 5px;
}
.filter-table ul:hover li:nth-child(6),
.filter-table ul:hover li:nth-child(5) {
  color: blue;
}
.clickable {
  width: 5px;
  margin-right: 0;
  height: 20px;
  cursor: pointer;
}
.clickable span {
  display: block;
  background: #bdc2d5;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-bottom: 4px;
}
.filter-table ul:hover li:last-child span {
  background: #2e5cf2;
}
/******* Contact Model ********/
.contact-model {
  width: 980px;
  background: #ffffff;
  border: 1px solid #eff2f6;
  box-sizing: border-box;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 50px 95px;
  text-align: center;
}
.conatc-overview {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #4f4f4f;
  margin-bottom: 30px;
}
.contact-form {
  display: flex;
  justify-content: space-between;
}
.contact-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}
.contact-form-wrapper label {
  width: 100%;
}
.contact-form-left {
  width: 47%;
}
.contact-txt {
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  margin-top: 10px;
  padding-left: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #666;
}
.contact-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 26px;
}
.contact-form-right {
  width: 47%;
}
.contact-form-wrapper textarea {
  resize: none;
  margin-top: 10px;
  height: 276px;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 15px;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  color: #666;
  padding-top: 10px;
}
.contact-model .btn-secondary {
  background: #2e5cf2;
  border-radius: 5px;
  height: 55px;
  margin: 20px auto 0;
  width: 260px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  border: 2px solid #2e5cf2;
  transition: 0.3s all ease-in-out;
}
.contact-model .btn-secondary:hover {
  background: #fff;
  color: #2e5cf2;
}
select.contact-txt {
  appearance: none;
  background: url("../images/down-arrow.png") no-repeat right 10px center;
}
.patient textarea {
  height: 163px;
}
.patient input.btn {
  width: 260px;
  height: 55px;
  margin-top: 30px;
}
.patient .section-title {
  color: #b27803;
}
.contact-model.patient {
  padding: 105px 95px;
}
/********* Landing Page *******/
.landing .login-left .title {
  margin-bottom: 45px;
  max-width: 481px;
  font-weight: bold;
}
.landing .login-left span {
  color: #2e5cf2;
}
.inner-header {
  display: flex;
  padding-bottom: 80px;
}
.header-left {
  width: 50%;
}
.header-right {
  display: flex;
  width: 53%;
  justify-content: flex-start;
}
.header-menu ul {
  display: flex;
  justify-content: flex-start;
}
.header-menu ul li a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 173.3%;
  color: #4f4f4f;
  margin-right: 35px;
}
.header-menu ul li.active a {
  color: #2e5cf2;
}
.header-menu ul li.down a {
  color: #b27803;
  text-transform: uppercase;
}
.header-image {
  display: flex;
  margin-top: 92px;
}
.header-left .login-left {
  margin-top: 100px;
}
.black-btn a {
  border: 3px solid #4f4f4f;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  max-width: 260px;
  height: 55px;
  justify-content: center;
  align-items: center;
}
.black-btn a span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #4f4f4f;
  transition: 0.3s all ease-in-out;
}
.black-btn a:hover {
  background: #4f4f4f;
}
.black-btn a:hover span {
  color: #fff;
}
.black-btn a span:nth-child(2) {
  margin-left: 15px;
}
.black-btn a:hover img {
  filter: brightness(0) invert(1);
  transition: 0.3s all ease-in-out;
}
.black-btn a span:first-child {
  margin-left: -15px;
  margin-right: 15px;
  margin-top: -5px;
}
.landing #header {
  border-bottom: 1px solid #eff2f6;
}
.our-vision-sec {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  line-height: 123.3%;
  color: #2e5cf2;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.our-vision-sec p {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #4f4f4f;
  max-width: 754px;
  margin: 0 auto;
}
.vision-box-wrapper {
  display: flex;
  padding-bottom: 50px;
}
.vision-box {
  width: calc(100% / 4 - 30px);
  margin: 0 15px;
  text-align: center;
}
span.vision-box-image {
  display: inline-block;
  margin-bottom: 10px;
  min-height: 75px;
}
.vision-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #2e5cf2;
  margin-bottom: 20px;
}
.choose-doctor {
  background: #e4e6ea;
  border: 1px solid #eff2f6;
  padding: 60px 0;
}
.choose-inner {
  display: flex;
  align-items: center;
}
.choose-left,
.choose-right {
  width: 25%;
}
.choose-center {
  width: 50%;
  position: relative;
}
.choose-figur-box {
  margin-bottom: 80px;
}
.choose-left .choose-figur-box {
  text-align: right;
}
.choose-figur-box h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 123.3%;
  text-align: right;
  color: #b27803;
}
.choose-right .choose-figur-box h2 {
  text-align: left;
}
.choose-figur-box {
  position: relative;
}
.choose-figur-box:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 80px;
  border-bottom: 1px dashed #bdc2d5;
  right: -91px;
  bottom: 12px;
}
.choose-right .choose-figur-box:after {
  right: auto;
  left: -91px;
}
.img-content {
  width: 270px;
  text-align: center;
  position: absolute;
  bottom: 125px;
  left: 0;
  right: 0;
  margin: auto;
}
.choose-figur-box h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 123.3%;
  color: #4f4f4f;
  margin-top: 7px;
}
.img-content h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 123.3%;
  color: #000;
  margin-top: 7px;
  margin-bottom: 10px;
}
.img-content h5 span {
  display: block;
}
.img-content p {
  line-height: 1.5;
  font-size: 13px;
  color: #999;
}
.choose-doctor-btn {
  text-align: center;
  margin-top: 50px;
}
.choose-doctor-btn .btn {
  display: inline-block;
  min-width: 260px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  color: #ffffff;
}
.choose-doctor-btn .btn:hover {
  background: #fff;
  color: #b27803;
}
.feedback {
  padding: 50px 0;
}
.feedback-rating {
  display: flex;
  max-width: 108px;
  margin-bottom: 15px;
}
.feedback-quotes p {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #4f4f4f;
}
.feedback-author {
  display: flex;
  align-items: center;
  margin-top: 14px;
  justify-content: flex-end;
}
.author-image {
  margin-right: 10px;
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.feedback-author h6 {
  text-align: right;
}
.feedback-quotes {
  padding: 30px;
  border: 1px solid #e4e6ea;
  position: relative;
  min-height: 210px;
}
.feedback-quotes:before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #e4e6ea transparent transparent transparent;
  border-width: 13px;
  right: 57px;
  bottom: -26px;
}
.feedback-quotes:after {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: white transparent transparent transparent;
  border-width: 13px;
  right: 57px;
  bottom: -24px;
}
.feedback-slider {
  display: flex;
  justify-content: space-between;
}
.feebackbox {
  width: 49%;
}
.contact-us {
  position: relative;
}
.contact-us-inner {
  display: flex;
}
.contact-us-left,
.contact-us-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 50px 0;
  position: relative;
  z-index: 9;
}
.contact-us:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  left: 0;
  background: #2e5cf2;
  top: 0;
}
.contact-us:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  right: 0;
  background: #b27803;
  top: 0;
}

.contact-us-left h3,
.contact-us-right h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 35px;
  line-height: 172.96%;
  color: #ffffff;
  margin-top: 18px;
}
.contact-btn {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  min-width: 350px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #2e5cf2;
  border: none;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.contact-btn:hover {
  opacity: 0.8;
}
.contact-btn-wrap {
  margin-top: 40px;
}
.contact-us-right .contact-btn {
  color: #b27803;
}

.footer {
  background: #172f7b;
  padding: 25px 0;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.footer-top-center a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 0 37px;
  display: block;
  transition: 0.3s all ease-in-out;
}
.footer-top-center ul {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-top-right ul {
  display: flex;
  justify-content: flex-end;
}
.footer-top-right ul a {
  padding: 0 18px;
  color: #fff;
  font-size: 25px;
  transition: 0.3s all ease-in-out;
}
.footer-top-right ul a:hover,
.footer-top-center a:hover {
  opacity: 0.8;
}
.footer-top-left .btn {
  display: flex;
  height: 46px;
  align-items: center;
  width: 174px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  justify-content: space-around;
}
.footer-top-left .btn img {
  filter: brightness(0) invert(1);
}
.footer-top-left {
  width: 25%;
  text-align: left;
}
.footer-top-center {
  width: 50%;
  text-align: center;
}
.footer-top-right {
  width: 25%;
  text-align: right;
}
.footer-bottom {
  margin-top: 14px;
}
.footer-bottom p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #bdc2d5;
  mix-blend-mode: normal;
  opacity: 0.35;
  text-align: center;
}
.dashboard-top {
  background: #2e5cf2;
}
.dashboard-top .container {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
}
.dahsboard-top-left {
  display: flex;
  align-items: center;
}
.dahsboard-top-left p,
.dahsboard-top-right p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #ffffff;
  margin-left: 20px;
}
.dahsboard-top-left img {
  filter: brightness(0) invert(1);
}
.dahsboard-top-right {
  display: flex;
  align-items: center;
}
.dahsboard-top-left .hide-btn {
  cursor: pointer;
  /* text-decoration: underline; */
}
.dashboard-footer .hide {
  bottom: 0;
  position: absolute;
  width: 100%;
}
.dashboard-footer {
  height: 100%;
  background: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
}
button.contact-btn:focus {
  outline: none;
}
.provider-pagination {
  margin-bottom: 0px !important;
}
.Pagination .hidden {
  display: none;
}
.provider-pagination .pagination {
  margin-right: 0px !important;
}
.provider-pagination .last-page {
  display: flex;
}
.provider-pagination .last-page a{
  margin-left: 10px;
}
.provider-pagination .prev-page {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}


.provider-pagination .next-page {
  margin-left: 30px;
  font-size: 14px;
  font-weight: 500;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.Pagination span:nth-child(2) {
  display: flex;
  margin: 0 5px;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin-right: -40px;
}
.pagination a {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 172.96%;
  color: #4f4f4f;
  padding: 0 10px;
}
.Pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10%;
}

.Pagination span:nth-child(2) a {
  margin-left: 15px;
  margin-right: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 172.96%;
  color: #4f4f4f;
  padding: 0 3px;
}
.pagination a:hover,
.Pagination span:nth-child(2) a:hover,
.pagination li.active a {
  color: #b27803;
  text-decoration: none;
}
.btn-secondary:hover {
  background: #2e5cf2;
  color: #fff;
  border-color: #2e5cf2;
  text-decoration: none;
}
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #315ce8;
  border-color: #315ce8;
  box-shadow: none;
}
.view button:focus {
  outline: none;
}
.view button:hover {
  outline: #315ce8;
}
.user-profile-wrapper {
  position: relative;
  cursor: pointer;
}
span.profile-edit {
  float: right;
  border-radius: 0;
}
span.profile-edit img {
  border-radius: 0;
}
.img-close-btn {
  position: absolute;
  top: 2px;
  right: 100px;
  background: #ffffff;
  border-radius: 50%;
  border: 0.5px solid;
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  cursor: pointer;
}
/********* Profile ************/
/****** Open one *****/
.openone-title .title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 123.3%;
  color: #b27803;
}
.openone-title span {
  color: #2e5cf2;
  font-size: 15px;
  font-weight: 600;
}
.profile-wrapper {
  display: flex;
  margin-top: 30px;
}
.profile-left {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  text-align: center;
  color: #4f4f4f;
  width: 40%;
  padding: 25px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.profile-right {
  width: 60%;
  padding-left: 30px;
}
.profile-right select {
  width: 100%;
  float: left;
  margin: 0;
  height: 45px;
  border: none;
  border-bottom: 1px solid;
}

.profile-right input,
.profile-left input {
  border-bottom: 1px solid black;
  width: 100%;
}
.profile-right .edit-btn {
  text-align: center;
}
.profile-right ul {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  padding: 18px;
}
.profile-right ul li:first-child {
  width: 25%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #b27803;
}
.profile-right ul li:nth-child(2) {
  width: 60%;
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #4f4f4f;
  margin-left: 5%;
}
.profile-right ul li:nth-child(3) {
  width: 10%;
}
.doctor-name {
  margin-top: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 172.96%;
  color: #4f4f4f;
  margin-bottom: 13px;
}
.doctor-email {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  color: #2e5cf2;
  margin-bottom: 20px;
}
.doctor-address p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 172.96%;
  text-align: center;
  color: #4f4f4f;
  max-width: 264px;
  margin: 10px auto 0;
}
.profile-btn-wrapper {
  padding-top: 60px;
  display: flex;
  justify-content: flex-end;
}

.profile-btn-wrapper .btn-save {
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2e5cf2;
  min-width: 220px;
  height: 45px;
  margin-left: 15px;
  border: 2px solid #2e5cf2;
  box-sizing: border-box;
  border-radius: 5px;
  background: transparent;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.profile-btn-wrapper .btn-save span:first-child {
  margin-right: 15px;
}
.profile-btn-wrapper .btn-save:hover {
  background: #2e5cf2;
  color: #fff;
}
.profile-btn-wrapper .btn-save:hover img {
  filter: brightness(0) invert(1);
  transition: 0.3s all ease-in-out;
}
.profile-left img {
  border-radius: 50%;
}
.btn-del {
  min-width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #4f4f4f;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
  margin-right: 25px;
}
.btn-del:hover {
  opacity: 0.8;
}
.btn-del span:first-child {
  margin-right: 15px;
}
.popover-content.popover-body {
  width: 150px;
  background: #ffffff;
  border: 3px solid #b27905;
  box-sizing: border-box;
  box-shadow: 0px 4px 44px rgba(185, 185, 205, 0.6);
}
.popover-content h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  margin: 15px;
  text-align: center;
}
.popover-content h3 span {
  font-size: 16px;
  cursor: pointer;
  color: #2e5cf2;
  font-weight: 600;
}
/* Error */
.contact-form-wrapper .error {
  border: 2px solid red;
}
.register .form-field-wrapper .error input, .user-register .form-field-wrapper .error input, 
.register .form-field-wrapper .error select {
  border: 1px solid red;
}
.img-preview {
  overflow: hidden;
}
.loader {
  justify-content: center !important;
  height: 100px;
}
.register-form {
  position: relative;
}
.div-loading:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
  left: 0;
}
.div-loading {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 66%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
}
.div-loading li {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  z-index: 9;
}
.physician-img {
  height: 125px;
  width: 125px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}
input.img_upload {
  position: absolute;
  left: 0;
  height: 125px;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 5;
}
.physician-img .overlay {
  height: 125px;
  position: absolute;
  background: #0000006b;
  width: 125px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

/********** New password *********/

.code-container {
  width: 400px;
  display: flex;
}
.code-container input {
  width: 70px !important;
  height: 50px !important;
}

.code-container input::-webkit-outer-spin-button,
.code-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.code-container input[type=number] {
  -moz-appearance: textfield;
}

/******* 02-07-2021 **********/
.form-bottom {
  display: flex;
  flex-direction: column;
  max-width: 330px;
  justify-content: center;
  text-align: center;
}
.form-bottom span {
  margin: 10px 0 10px;
  font-size: 16px;
  line-height: 172%;
  text-transform: lowercase;
}
.btn-facebook {
  display: flex;
  align-items: center;
  background: #ededfd;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.btn-facebook:hover {
  background: #2e5cf2;
}
.btn-facebook label {
  color: #2E5CF2;
  font-weight: 600;
  margin-left: 30px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.btn-facebook:hover label{
  color: #fff;
}

.btn-facebook:hover .facebook-icon
{
  background: #fff;
  color: #2e5cf2 !important;
}
.facebook-icon {
  color: #fff !important;
  font-size: 15px;
  cursor: pointer;
  background: #2E5CF2;
  padding: 7px 11px;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
}


/********* Responsive *********/
@media (max-width: 1169px) {
  .container {
    width: 96%;
  }
  .login {
    background-size: 62% !important;
  }
  .header-image {
    display: flex;
    margin-top: 92px;
    width: 100%;
    overflow: hidden;
  }
  .header-image img {
    width: 25% !important;
  }

  /******* Regiter Page ********/
  .register,
  .login {
    background: none !important;
  }
  .login-left {
    margin-top: 10px;
  }
  .register .form-field-wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
  .form-field-wrapper .txt {
    max-width: 100% !important;
  }
  .form-field.uploadfile {
    max-width: 100% !important;
  }
}
@media (max-width: 1100px) {
  .header-menu ul li a {
    margin-right: 20px !important;
  }
}

@media (max-width: 1024px) {
  .header-menu ul li a {
    margin-right: 11px !important;
    font-size: 14px !important;
  }
  .logo {
    width:auto;
  }
  .header-right {
    width: 75%;
    display: flex;
    justify-content: flex-end;
}
.header-right-left {
  width: auto;
}
.header-right-right {
  width: auto;
}
.header-right-left ul li {
  margin-right: 10px;
}
.current-user {
  padding-left: 10px;
}
}
@media (max-width: 991px) {
  .header-right {
    width: 60%;
  }
  .header-menu ul li a {
    margin-right: 5px !important;
    font-size: 13px !important;
  }
  .logo {
    width: 60%;
  }
  .vision-box-wrapper {
    flex-wrap: wrap;
  }
  .vision-box {
    width: calc(100% / 2 - 30px) !important;
    margin: 0 15px 30px !important;
  }
  .choose-left {
    width: 25%;
    padding-right: 13px;
  }
  .choose-right {
    padding-left: 8px;
  }
  .choose-right .choose-figur-box:after {
    display: none;
  }
  .contact-us-left h3,
  .contact-us-right h3 {
    font-size: 26px !important;
  }
  .contact-btn {
    min-width: 250px !important;
  }
  .footer-top-center a {
    padding: 0 15px !important;
  }
  .notlogin {
    margin-top: 40px;
    max-width: 100%;
    width: 100%;
  }
  /****** Dashboard *******/
  .dropdown-menu-arrow {
    margin-left: 24px;
  }
  .main-header .logo {
    width: 25%;
  }
  .header-right {
    width: 75%;
  }
  .btn-secondary {
    padding: 0px 10px;
  }
  .inner-header.main-header {
    padding-bottom: 40px !important;
  }
  .filter-table ul {
    width: 1200px !important;
  }
  .filter-table {
    margin-top: 30px;
    overflow-x: auto !important;
  }
  .filter-table ul li:nth-child(2) {
    width: 12.5%;
  }
  #section {
    min-height: 500px !important;
  }
  .dashboard-footer .hide {
    bottom: 0;
    position: relative !important;
    width: 100%;
  }
  .modal-90w {
    width: auto;
    max-width: none !important;
  }
}

@media (max-width: 767px) {
  .inner-header .login-header-menu {
    padding: 0 !important;
    margin-top: 10px;
  }
  .inner-header {
    padding-bottom: 50px !important;
    flex-direction: column;
  }
  .our-vision-sec p {
    font-size: 15px !important;
  }
  .header-left {
    width: auto;
  }
  .header-left {
    width: 100% !important;
    text-align: center !important;
  }
  .logo a {
    display: block !important;
    text-align: center !important;
    width: 100% !important;
  }
  .logo a {
    text-align: left !important;
    }
  .logo a img {
    display: inline-block !important;
    width: 17% !important;
  }
  .header-left .login-left {
    margin-top: 30px !important;
  }
  .header-image {
    display: flex;
    margin-top: 50px !important;
    width: 100% !important;
  }
  .header-right {
    width: 100% !important;
  }
  .black-btn a {
    margin: 0 auto !important;
  }
  .vision-title {
    margin-bottom: 10px !important;
  }
  .choose-inner {
    display: flex;
    align-items: center !important;
    flex-direction: column !important;
  }
  .choose-left,
  .choose-right {
    width: 100% !important;
  }
  .choose-figur-box {
    margin-bottom: 50px !important;
  }
  .choose-left .choose-figur-box {
    text-align: center !important;
  }
  .choose-figur-box h2 {
    text-align: center !important;
  }
  .choose-right .choose-figur-box h2 {
    text-align: center !important;
  }
  .choose-figur-box h4 {
    text-align: center !important;
  }
  .choose-center {
    width: 100% !important;
    position: relative;
    margin-bottom: 30px !important;
  }
  .choose-figur-box {
    margin-bottom: 25px !important;
    padding-bottom: 25px !important;
    border-bottom: 1px dashed #ccc;
  }
  .choose-figur-box:after {
    display: none !important;
  }
  .feebackbox {
    width: 100% !important;
  }
  .contact-us-left h3,
  .contact-us-right h3 {
    font-size: 18px !important;
  }
  .contact-btn {
    min-width: 150px !important;
    height: 40px !important;
  }
  .footer-top {
    flex-direction: column;
  }
  .footer-top-left {
    width: auto !important;
    text-align: left;
    margin-bottom: 30px;
  }
  .footer-top-center {
    width: 100% !important;
    text-align: center;
    order: -1;
    margin-bottom: 30px;
  }
  .footer-bottom {
    margin-top: 14px;
  }
  .footer-top-right ul {
    display: flex;
    justify-content: center !important;
  }
  .contact-btn-wrap {
    margin-top: 20px;
  }
  li.down {
    display: none;
  }

  .landing .login-left .title {
    margin-bottom: 45px;
    max-width: 100% !important;
  }
  .header-menu ul {
    justify-content: center !important;
    margin-top: 20px !important;
  }
  .logo a img {
    display: inline-block !important;
    width: 20% !important;
  }
  .header-menu ul li a {
    margin-right: 35px !important;
    font-size: 24px !important;
  }
  .choose-doctor-btn {
    text-align: center !important;
    margin-top: 30px !important;
  }
  .landing .login-left .title {
    margin-bottom: 25px !important;
  }
  /***** Regiter Page ******/
  .register .login-left .title {
    margin-bottom: 10px;
    text-align: center;
  }
  .login-link {
    text-align: center;
  }
  .register .form-field-wrapper {
    max-width: 100% !important;
    width: 100% !important;
    flex-direction: column;
    margin-bottom: 0 !important;
  }
  .form-field-wrapper .form-field {
    width: 100%;
    margin-bottom: 15px;
  }
  .form-field.form-title select {
    width: 100%;
  }
  .btn-wrapper .btn {
    max-width: 100% !important;
  }
  .notlogin p {
    flex-direction: column !important;
    text-align: center !important;
  }
  .notlogin p a {
    margin: 0 17px 10px !important;
  }
  .profile-wrapper {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
  }
  .profile-left {
    width: 100% !important;
    margin-bottom: 30px !important;
  }
  .profile-right {
    width: 100% !important;
    padding-left: 0 !important;
  }
  .header-right-left ul li {
    margin-right: 15px;
  }
  .header-right-left ul li span {
    font-size: 14px;
  }
  .main-header .logo {
    margin-bottom: 30px !important;
  }
  .profile-btn-wrapper {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .btn-del {
    min-width: initial !important;
  }
  .openone-title .title {
    font-size: 22px !important;
  }
  .feedback-slider {
    flex-direction: column !important;
  }
  .feedback-quotes {
    margin-top: 30px !important;
    min-height: 0 !important;
  }
  .login-link {
    font-size: 15px;
    text-align: left !important;
}
.form-field-wrapper label {
  font-size: 14px;
}
.header-differ .inner-header.main-header {
  flex-direction: row;
}
.header-differ .inner-header.main-header .header-right {
  flex-direction: row;
}
.header-differ .inner-header.main-header .logo {
  width: 100px;
}
.header-differ .inner-header.main-header .logo a img {
  width: 90% !important;
}
.header-differ .inner-header.main-header .dropdown-menu-arrow {
  margin-left: 0;
}
.notification .btn-secondary {
  padding: 0 5px;
}
.notification .btn-secondary p {
  margin-right: 4px;
}
span.user-image img {
  border-radius: 50%;
  height: 36px !important;
}
.header-right-left ul li {
  margin-right: 5px;
  margin-top: 0px;
}
}

@media (max-width: 480px) {
  .landing .login-left .title {
    margin-bottom: 25px !important;
    max-width: 100% !important;
  }
  .title {
    font-size: 28px !important;
  }
  .header-left .login-left {
    margin-top: 25px !important;
  }
  .logo a img {
    display: inline-block !important;
    width: 25% !important;
  }
  .title {
    font-size: 20px !important;
  }
  .black-btn a {
    max-width: 240px !important;
    height: 47px !important;
  }
  .header-image {
    margin-top: 20px !important;
  }
  .inner-header {
    padding-bottom: 30px !important;
  }
  .our-vision-sec {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .our-vision-sec p {
    font-size: 14px !important;
    line-height: 150% !important;
  }
  .vision-box {
    width: 100% !important;
    margin: 0 0px 30px !important;
  }
  .img-content {
    width: 260px !important;
    bottom: 85px !important;
  }
  .choose-doctor-btn {
    margin-top: 20px !important;
  }
  .contact-us-inner {
    display: flex !important;
    flex-direction: column !important;
  }
  .contact-us:before {
    height: 50% !important;
    width: 100% !important;
  }
  .contact-us:after {
    height: 50% !important;
    width: 100% !important;
    top: 50% !important;
  }
  .contact-us-left,
  .contact-us-right {
    width: 100% !important;
  }
  .contact-us-left h3,
  .contact-us-right h3 {
    font-size: 28px !important;
  }
  .contact-btn-wrap {
    margin-top: 20px !important;
  }
  .footer-top-center a {
    padding: 0 10px !important;
  }
  .header-right {
    width: 100% !important;
    flex-direction: column;
  }
  .header-right-left {
    width: 100%;
    margin-bottom: 20px;
  }
  .header-right-right {
    width: 100%;
  }
  .current-user {
    justify-content: flex-end !important;
    border-left: none !important;
  }
  .openone-title .title {
    font-size: 17px !important;
  }
  .profile-right ul {
    flex-direction: column !important;
  }
  .profile-right ul li:first-child {
    width: 100% !important;
  }
  .profile-right ul li:nth-child(2) {
    width: 100% !important;
    margin: 15px 0 !important;
  }
  .profile-btn-wrapper {
    flex-direction: column;
  }
  .profile-btn-wrapper .btn-del {
    margin-right: 0;
    order: 2;
    margin-top: 20px;
  }
  .profile-btn-wrapper .btn-save {
    margin-left: 0 !important;
  }
  .header-right-left ul {
    justify-content: center;
  }
  .dahsboard-top-right {
    display: none !important;
  }
  .header-menu ul li a {
    margin-right: 10px !important;
    font-size: 17px !important;
  }
  .user-register,.user-login {
    background-position: bottom right !important;
    background-size: 309px 347px !important;
  }
  .code-container {
    width: auto;
    display: flex;
}
.code-container input {
  width: 57px !important;
  height: 50px !important;
}
span.username {
  display: none;
}
span.user-image img {
  border-radius: 50%;
  height: auto !important;
}
.header-right-left {
  width: 100%;
  margin-bottom: 0;
  margin-right: -65px;
}
}

.code-container input {
  margin-right: 16px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
}

/* range slider */
.rangeslider-horizontal {
  height: 3px !important;
  width: 445px !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #2E5CF2 !important;
}
.rangeslider-horizontal .rangeslider__handle:after {
  display: none !important;
}
.rangeslider .rangeslider__handle {
  box-shadow: none !important;
}
.rangeslider-horizontal .rangeslider__handle {
  border: 3px solid #2E5CF2 !important;
  width: 25px !important;
  height: 25px !important;
}
.rangeslider__handle-tooltip {
  display: none !important;
}







