/* header css */
.mh-header {
  padding: 10px 0;
  background-color: #fff;
}
.mh-header .header-row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.mh-header .mh-menu ul {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mh-header .mh-menu ul li.active a:before,
.mh-header .mh-menu ul li:hover a:before {
  content: "";
  position: absolute;
  top: -15px;
  width: 100%;
  height: 5px;
  background: #b27803;
  left: 0;
}
.mh-header .mh-menu ul li.active a,
.mh-header .mh-menu ul li:hover a {
  color: #b27803;
}
.mh-header .mh-menu ul li {
  padding: 0 20px;
}
.mh-header .mh-menu ul li a {
  position: relative;
  color: #4f4f4f;
  padding: 20px 0;
  text-decoration: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}
.mh-header .right-inner {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mh-header .right-inner .icon {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 30px;
  margin-left: 20px;
}
.mh-header .right-inner .icon .notification {
  margin: 20px;
}
.mh-header .right-inner .icon .notification .notification-link {
  position: relative;
}
.mh-header .right-inner .icon .notification .notification-link span {
  border-radius: 50%;
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: #2e5cf2;
  right: -2px;
  top: -2px;
}
.mh-header .mh-user:after {
  content: "";
  right: 0;
  width: 13px;
  height: 8px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.mh-header .down-logout {
  cursor: pointer;
}
.mh-header .mh-user:before {
  position: absolute;
  content: "";
  left: 0;
  width: 1px;
  height: 100%;
  background: #bdc2d5;
}
.mh-header .mh-user {
  position: relative;
  padding: 0 30px;
}
b.active-user-profile {
  color: #b27803 !important;
}
.mh-header .user-link b {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 123.3%;
  color: #4f4f4f;
}
.mh-header .user-link b.on-profile {
  color: #b27803 !important;
}
.mh-header .user-link {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}
.mh-header .user-link .img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
.mh-header .user-link span {
  display: block;
  color: #4f4f4f;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 123.3%;
}
.mh-header .user-link .sub-menu {
  display: none;
}

/* .mh-header .user-link .sub-menu ul */

.search-section .advance-search {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}
.search-section .advance-search img {
  margin: 0 20px 0 0;
}
.search-section .advance-search span {
  color: #fff;
}
.search-section {
  background-color: #2e5cf2;
  height: 234px;
  padding: 40px 0;
}
.search-section h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  color: #ffffff;
  text-align: center;
}
.find-doctor {
  background: #f6f6f6;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 25px 0;
}
.find-doctor .form-inner {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: flex;
}
.find-doctor .form-inner .select.box1 {
  border-right: 1px solid #bdc2d5;
  padding-right: 10px;
  position: relative;
}
.find-doctor .form-inner .select.box1 img {
  margin: 0;
}
.find-doctor .form-inner .select.box2 .autocomplete-dropdown-container {
  position: absolute !important;
  top: 43px !important;
  width: 100% !important;
  background: #fff !important;
  border-radius: 10px !important;
}
.find-doctor .form-inner .select.box1 input {
  width: 100%;
  padding: 0 10px;
}
.find-doctor .form-inner .select.box2 input {
  width: 100%;
  padding: 0 10px;
}
.find-doctor .form-inner .select.box2 img {
  margin: 0;
}


.find-doctor .form-inner .select.box1 .down-arrow {
  margin-left: auto;
  cursor: pointer;
}
.find-doctor .form-inner .select.box2 .down-arrow {
  cursor: pointer;
}

.find-doctor .form-inner .select {
  position: relative;
  width: 55%;
  margin-right: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
}
.find-doctor .form-inner .select select {
  width: 100%;
  border: 0;
  background-color: transparent;
}
.find-doctor .form-inner .submit-btn {
  width: 20%;
  text-align: right;
}
.find-doctor .form-inner .submit-btn input:hover {
  color: #2e5cf2;
  background: #fff;
}
.find-doctor .form-inner .submit-btn input {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: #fff;
  background: #2e5cf2;
  border: 1px solid #2e5cf2;
  border-radius: 10px;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}
.find-doctor .drop-down-list select option:hover {
  background-color: rgb(236 236 236);
}
.find-doctor .drop-down-list select option:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.find-doctor .drop-down-list select option:last-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.find-doctor .drop-down-list select option {
  padding: 5px 15px;
}
.find-doctor .drop-down-list select {
  height: 250px;
}
.find-doctor .drop-down-list {
  position: absolute;
  top: 43px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
}

/* category search  */
.searchcat-container {
  width: 100%;
  padding: 0px 20px;
}
.searchcat-container input {
  width: 100%;
}

.search-filter .inner-fliter {
  position: absolute;
  box-shadow: 0px 2px 12px rgb(0 0 0 / 25%);
  z-index: 9;
  width: 100%;
  background-color: #fff;
  left: 0;
  margin-top: 28px;
  padding: 30px;
}
.search-filter .range-fliter {
  max-width: 450px;
}
.search-filter .range-fliter .text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-filter .range-fliter .text span {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: right;
  color: #4f4f4f;
}
.search-filter .range-fliter .text p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4f4f4f;
}

.search-filter .btn-list h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4f4f4f;
}

.search-filter .btn-list ul {
  margin: 30px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.search-filter .btn-list ul li.active:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../assets/images/btn-frame.png);
  width: 16px;
  height: 16px;
}
.search-filter .btn-list ul li.active,
.search-filter .btn-list ul li:hover {
  background: #eff3fe;
  color: #2e5cf2;
}
.search-filter .btn-list ul li {
  padding: 10px 15px;
  transition: all 0.3s;
  position: relative;
  background: #f6f6f6;
  border-radius: 10px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  margin: 15px 10px 0 0;
  cursor: pointer;
}

.search-filter .fliter-btn ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.search-filter .fliter-btn li {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #2e5cf2;
  border-radius: 10px;
  height: 45px;
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  cursor: pointer;
}
.search-filter .fliter-btn li:last-child {
  margin-right: 0;
}
.search-filter .fliter-btn li.theme-btn:hover {
  background-color: #fff;
  color: #2e5cf2;
}
.search-filter .fliter-btn li.theme-btn {
  background: #2e5cf2;
  transition: all 0.5s;
  border: 1px solid #2e5cf2;
  color: #fff;
  font-weight: normal;
}

.locationchange-modal span {
  color: black !important;
}
.form-control.locationchange-modal input {
  height: 100%;
  width: 93%;
}
.locationchange-modal .autocomplete-dropdown-container {
  margin-top: -40px !important;
}

.head-location {
  max-width: 200px;
  overflow: scroll;
  line-height: 20px !important;
  height: 20px;
}

.head-location::-webkit-scrollbar-track {
  display: none;
}

.head-location::-webkit-scrollbar {
  display: none;
}
.user-box.head-user-newblock {
  width: 240px;
}

.logout-dropdown-menu {
  position: absolute;
  width: 100%;
  background: #2e5cf2;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  margin-top: 15px;
  display: block;
  padding: 0;
  transition: 0.5s all ease-in-out;
}
.head-user-newblock .logout-dropdown-menu a {
  padding: 10px;
  border-bottom: 1px solid;
  color: #fff;
  font-weight: 600;
  transition: 0.5s all ease-in-out;
  text-decoration: none;
}
.head-user-newblock .logout-dropdown-menu ul {
  color: #fff;
  text-align: center;

}

.mh-header .hemburger {
  display: none;
}
.mh-header .mh-menu-mob {
  display: none;
}

@media(max-width: 1024px)
{
  .all-providers .details-list .provider-data li .inner-text {
    margin-left: 20px;
    width: 60%;
}
.star-div {
  width: 20%;
}
.all-providers .details-list .provider-data li .star-div .user-detailes-btn {
  padding: 5px 12px;
  width: 100%;
  text-align: center;
}
.all-providers .details-list .provider-data li .user-details {
  align-items: flex-start;
}
.all-providers .details-list .provider-data li .user-details p {
  width: 80% !important; 
}
.all-providers .details-list .provider-data li .user-details {
  flex-wrap: wrap;
}
.all-providers .details-list .provider-data li .location {
  margin-left: 39px;
  margin-top: 10px;
}
.left-inner {
  width: 15%;
}
.mh-header .right-inner {
  width: 15%;
}
.logo {
  width: 100%;
}
.mh-header .right-inner .icon {
  padding: 0 0px;
  margin-left: 0;
}
.user-box.head-user-newblock {
  width: auto;
}
.user-box.head-user-newblock .text {
  display: none;
}
.mh-header .mh-user:before
{
  display: none;
}
.mh-header .mh-user {
  position: relative;
  padding: 0 00px 0 30px;
}
}
@media(max-width: 767px)
{
  .mh-header .mh-menu ul li {
    width: 100%;
    text-align: center;
}
.find-doctor .form-inner .select.box2 img {
  padding-right: 9px;
}
.mh-header .mh-menu ul li a {
  padding: 10px 0 10px 0;
}
.mh-header .mh-menu ul li.active a:before, .mh-header .mh-menu ul li:hover a:before
{
  /* display: none; */
}
.mh-header .logo a img {
  display: inline-block !important;
  width: 65px !important;
}
.mh-header .logo {
  width: 100%;
}
.mh-header .left-inner {
  width: 30%;
}
.mh-header .right-inner {
 flex-wrap: nowrap;
}
.user-box.head-user-newblock .text {
  display: none;
}
.mh-header .right-inner {
  flex-wrap: nowrap;
  width: 70%;
  justify-content: flex-end;
}
.mh-header .right-inner .icon {
  padding: 0 10px;
  margin-left: 10px;
}
.user-box.head-user-newblock {
  width: auto;
}
.mh-header .mh-user {
  position: relative;
  padding: 0 10px;
}
.mh-header .mh-user:before{
  display: none;
}
.mh-header .down-logout {
  cursor: pointer;
}
.modal-container .modal-header {
  border-bottom: 0;
  padding-top: 10px;
}
.modal-container h1 {
  font-size: 20px;
  margin-bottom: 0px !important;
  margin-top: 14px !important;
  text-align: left !important;
  margin-right: auto;
  margin-left: 0;
}
.modal-container .modal-content {
  height: auto;
  top: 100px;
}
.rangeslider-horizontal {
  height: 3px !important;
  width: 100% !important;
}
.search-filter .inner-fliter {
  padding: 30px 0;
}
.search-filter .btn-list ul li {
  padding: 5px 10px;
}
.mh-header .mh-menu {
  display: none;
}
.mh-header .hemburger {
  display: block;
}
.mh-header .mh-menu-mob {
  display: block;
  position: absolute;
  width: 100%;
  background: #fff;
  top: 80px;
  text-align: center;
  transition: 0.3s all ease-in-out;
  z-index: 99;
}
.mh-header .hemburger {
  display: block;
  position: absolute;
  left: 100px;
  top: 31px;
  font-size: 32px;
  color: #2e5cf2;
}
.mh-menu-mob a {
  margin: 10px 0;
  font-weight: bold;
}
.mh-menu-mob li.active a {
  color: #B27803 !important;
}
.find-doctor .drop-down-list {
  z-index: 9;
}
}

.category-search-section {
  height: 100%;
}
