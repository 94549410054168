@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("../node_modules//bootstrap/dist/css/bootstrap.min.css");
@import url("https://unpkg.com/aos@2.3.1/dist/aos.css");
@import url("./assets/css/styles.css");

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 4px;
	height: 0px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #2E5CF2;
}